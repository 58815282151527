/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_vertical_dots.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const VerticalDotsIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M8.28571429,4.65714286 C7.40331429,4.65714286 6.68571429,3.93954286 6.68571429,3.05714286 C6.68571429,2.17474286 7.40331429,1.45714286 8.28571429,1.45714286 C9.16811429,1.45714286 9.88571429,2.17474286 9.88571429,3.05714286 C9.88571429,3.93954286 9.16811429,4.65714286 8.28571429,4.65714286 Z M8.28571429,6.25714286 C9.16811429,6.25714286 9.88571429,6.97474286 9.88571429,7.85714286 C9.88571429,8.73954286 9.16811429,9.45714286 8.28571429,9.45714286 C7.40331429,9.45714286 6.68571429,8.73954286 6.68571429,7.85714286 C6.68571429,6.97474286 7.40331429,6.25714286 8.28571429,6.25714286 Z M8.28571429,11.0571429 C9.16811429,11.0571429 9.88571429,11.7747429 9.88571429,12.6571429 C9.88571429,13.5395429 9.16811429,14.2571429 8.28571429,14.2571429 C7.40331429,14.2571429 6.68571429,13.5395429 6.68571429,12.6571429 C6.68571429,11.7747429 7.40331429,11.0571429 8.28571429,11.0571429 Z"
        transform=""
      />
    </g>
  </svg>
);

VerticalDotsIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

VerticalDotsIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default VerticalDotsIcon;
