import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import IconButtonStyles from "./IconButtonStyles";

import { ColorPalette } from "../StylingConstants";

const baseStyles = css`
  align-self: center; // generally helps vertical centering. Override as needed
  border: 0px solid transparent;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;

  ${props => {
    if (props.size === IconButtonStyles.LARGE) {
      return `
        height: 32px;
        line-height: 16px;
        padding: 8px;
        width: 32px;
        svg {
          height: 16px;
          width: 16px;
        }
        .flex-input + & {
          // align icon buttons with our inputs
          // we move up from the bottom because flex-input may have a label above, so centering
          // and positioning from the top are not reliable
          align-self: flex-end;
          margin-bottom: 4px;
        }

      `;
    }
    if (props.size === IconButtonStyles.TINY) {
      return `
        height: 16px;
        line-height: 12px;
        padding: 1px;
        width: 16px;

        svg {
          width: 12px;
          height: 12px;
        }

      `;
    }
    return `
      height: 26px;
      line-height: 16px;
      padding: 6px;
      width: 26px;

      svg {
        height: 14px;
        width: 14px;
      }

      .flex-input + & {
        // align icon buttons with our inputs
        // we move up from the bottom because flex-input may have a label above, so centering
        // and positioning from the top are not reliable
        align-self: flex-end;
        margin-bottom: 8px;

      }
    `;
  }}

  &:focus {
    outline-width: 0;
  }

  background: transparent;

  path,
  polygon,
  rect {
    fill: ${ColorPalette.lightWhite};
  }

  &:hover {
    background: ${ColorPalette.minWhite};
  }

  &:active${props => (props.active ? ", &" : "")} {
    background: ${ColorPalette.faintWhite};
  }

  &,
  &:hover,
  &:active${props => (props.active ? ", &" : "")} {
    &:disabled {
      background-color: transparent;

      path,
      polygon,
      rect {
        fill: rgba(255, 255, 255, 0.2);
      }
    }
  }
`;

const StyledLink = styled(Link).attrs({ className: "icon-btn" })`
  ${baseStyles}
`;
const StyledA = styled.a.attrs({ className: "icon-btn" })`
  ${baseStyles}
`;
const StyledButton = styled.button.attrs({ className: "icon-btn" })`
  ${baseStyles}
`;

/*
 * Yuka v2.0
 * Wrapper component for SVG icon buttons.
 *
 * @return {Element}
 */
const IconButton = props => {
  if (props.to) {
    return (
      <StyledLink
        active={props.active}
        size={props.buttonStyle || props.style}
        className={props.className}
        data-testid={props.dataTestid}
        to={props.to}
      >
        {props.children}
      </StyledLink>
    );
  }
  if (props.href) {
    return (
      <StyledA
        active={props.active}
        size={props.buttonStyle || props.style}
        className={props.className}
        data-testid={props.dataTestid}
        href={props.href}
      >
        {props.children}
      </StyledA>
    );
  }
  return (
    <StyledButton
      active={props.active}
      size={props.buttonStyle || props.style}
      className={props.className}
      data-testid={props.dataTestid}
      disabled={props.disabled}
      onClick={props.onClick}
      type={props.type} // eslint-disable-line react/button-has-type
    >
      {props.children}
    </StyledButton>
  );
};

IconButton.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataTestid: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string, // renders a <a>
  onClick: PropTypes.func,
  style: PropTypes.oneOf(Object.values(IconButtonStyles)),
  buttonStyle: PropTypes.oneOf([...Object.values(IconButtonStyles), ""]),
  to: PropTypes.string, // renders a react-router Link
  type: PropTypes.string,
};

IconButton.defaultProps = {
  active: false,
  className: "",
  dataTestid: "",
  disabled: false,
  href: null,
  onClick: _.noop,
  style: IconButtonStyles.DEFAULT,
  to: null,
  type: "button",
  buttonStyle: "",
};

export default IconButton;
