/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_flash.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const FlashIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule=""
        d="M13.2417347,5.71991254 L7.99772512,5.71991254 L7.99772512,0.918142022 C7.99772512,0.000707503566 6.9994519,-0.346736757 6.58973408,0.4253616 L2.12068727,8.85372352 C1.79707643,9.46345414 2.16232142,10.2616676 2.76412404,10.2616676 L7.99772512,10.2616676 L7.99772512,15.0895532 C7.99772512,15.9990396 8.98653602,16.344213 9.39341515,15.5777919 L13.8804403,7.11650222 C14.2012125,6.51131336 13.8388062,5.71991254 13.2417347,5.71991254"
        transform=""
      />
    </g>
  </svg>
);

FlashIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

FlashIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default FlashIcon;
