/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_clock.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ClockIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M8,0 C3.5888,0 0,3.5888 0,8 C0,12.4112 3.5888,16 8,16 C12.4112,16 16,12.4112 16,8 C16,3.5888 12.4112,0 8,0 Z M8,14.4 C4.4712,14.4 1.6,11.5288 1.6,8 C1.6,4.4712 4.4712,1.6 8,1.6 C11.5288,1.6 14.4,4.4712 14.4,8 C14.4,11.5288 11.5288,14.4 8,14.4 Z M8.8,7.6688 L8.8,4 C8.8,3.5581722 8.4418278,3.2 8,3.2 C7.5581722,3.2 7.2,3.5581722 7.2,4 L7.2,8 C7.2,8.2128 7.284,8.416 7.4344,8.5656 L9.8344,10.9656 C9.98378968,11.1167635 10.1874731,11.201837 10.4,11.201837 C10.6125269,11.201837 10.8162103,11.1167635 10.9656,10.9656 C11.1161674,10.8158919 11.2008279,10.6123277 11.2008279,10.4 C11.2008279,10.1876723 11.1161674,9.9841081 10.9656,9.8344 L8.8,7.6688 Z"
        transform=""
      />
    </g>
  </svg>
);

ClockIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ClockIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default ClockIcon;
