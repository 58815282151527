import PropTypes from "prop-types";
import styled from "styled-components";

import {
  elevation0,
  elevation1,
  elevation2,
  elevation3,
  elevation4,
  elevation5Raised,
  elevation5Flat,
} from "../Elevation";

const Elevations = {
  0: elevation0,
  1: elevation1,
  2: elevation2,
  3: elevation3,
  4: elevation4,
  5: elevation5Raised,
  "5-flat": elevation5Flat,
};

/**
 * Yuka v2.0
 * A rudimentary design component that renders a card at a specified elevation level
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const BasicCard = styled.div.attrs(props => ({ className: `zx-card elevation${props.elevation}` }))`
  ${props => Elevations?.[props.elevation] || elevation3}
  border-radius: 8px;
  box-sizing: border-box;
  min-height: 0;
  padding: ${props => (props.small ? "12px" : "16px")};
`;

BasicCard.propTypes = {
  elevation: PropTypes.number,
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
  className: PropTypes.string,
};

BasicCard.defaultProps = {
  elevation: 3,
  small: false,
  className: "",
};

export default BasicCard;
