/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_hamburger.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const HamburgerIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.2,8.65714286 L0.8,8.65714286 C0.3581722,8.65714286 -3.19744231e-14,8.29897066 -3.19744231e-14,7.85714286 C-3.19744231e-14,7.41531506 0.3581722,7.05714286 0.8,7.05714286 L15.2,7.05714286 C15.6418278,7.05714286 16,7.41531506 16,7.85714286 C16,8.29897066 15.6418278,8.65714286 15.2,8.65714286 Z M15.2,3.05714286 L0.8,3.05714286 C0.3581722,3.05714286 -3.19744231e-14,2.69897066 -3.19744231e-14,2.25714286 C-3.19744231e-14,1.81531506 0.3581722,1.45714286 0.8,1.45714286 L15.2,1.45714286 C15.6418278,1.45714286 16,1.81531506 16,2.25714286 C16,2.69897066 15.6418278,3.05714286 15.2,3.05714286 Z M15.2,14.2571429 L0.8,14.2571429 C0.3581722,14.2571429 -3.19744231e-14,13.8989707 -3.19744231e-14,13.4571429 C-3.19744231e-14,13.0153151 0.3581722,12.6571429 0.8,12.6571429 L15.2,12.6571429 C15.6418278,12.6571429 16,13.0153151 16,13.4571429 C16,13.8989707 15.6418278,14.2571429 15.2,14.2571429 Z"
        transform=""
      />
    </g>
  </svg>
);

HamburgerIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

HamburgerIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default HamburgerIcon;
