import PropTypes from "prop-types";
import styled from "styled-components";

import { overline, FontColors } from "../Typography";

/**
 * Yuka v1.0
 * Basic header for List styles
 *
 * @param {object} props
 * @returns {React.Element}
 */
const ListHeader = styled.div`
  ${overline}
  ${FontColors.faint}
  margin: 0 12px 12px;
`;

ListHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

/* @component */
export default ListHeader;
