/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_face_happy.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const FaceHappyIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M8,0 C3.5888,0 0,3.5888 0,8 C0,12.4112 3.5888,16 8,16 C12.4112,16 16,12.4112 16,8 C16,3.5888 12.4112,0 8,0 M8,14.4 C4.4712,14.4 1.6,11.5288 1.6,8 C1.6,4.4712 4.4712,1.6 8,1.6 C11.5288,1.6 14.4,4.4712 14.4,8 C14.4,11.5288 11.5288,14.4 8,14.4 M5.6,7.2 C6.0418278,7.2 6.4,6.8418278 6.4,6.4 C6.4,5.9581722 6.0418278,5.6 5.6,5.6 C5.1581722,5.6 4.8,5.9581722 4.8,6.4 C4.8,6.8418278 5.1581722,7.2 5.6,7.2 M10.4,5.6 C9.9581722,5.6 9.6,5.9581722 9.6,6.4 C9.6,6.8418278 9.9581722,7.2 10.4,7.2 C10.8418278,7.2 11.2,6.8418278 11.2,6.4 C11.2,5.9581722 10.8418278,5.6 10.4,5.6 M9.8432,9.0248 C9.8192,9.0488 9.2144,9.6008 8,9.6008 C6.8024,9.6008 6.1984,9.0648 6.1576,9.0264 C5.84167502,8.72465139 5.34209722,8.73215042 5.03537148,9.04324547 C4.72864573,9.35434053 4.72821327,9.85397443 5.0344,10.1656 C5.14,10.272 6.1224,11.2 8,11.2 C9.8768,11.2 10.86,10.272 10.9656,10.1656 C11.2730625,9.8571462 11.2748467,9.35864674 10.9696,9.048 C10.6630504,8.73493029 10.1623804,8.72461819 9.8432,9.0248"
        transform=""
      />
    </g>
  </svg>
);

FaceHappyIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

FaceHappyIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default FaceHappyIcon;
