/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_edit.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const EditIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.1024,0.868 C13.9464,-0.2888 11.9288,-0.2888 10.7712,0.868 L1.8352,9.804 C1.75312857,9.88724207 1.69079542,9.98784997 1.6528,10.0984 C1.6504,10.1056 1.6432,10.1104 1.6408,10.1176 L0.0408,14.9176 C-0.0557400232,15.2051603 0.018834714,15.5225877 0.2333235,15.7370765 C0.447812286,15.9515653 0.765239741,16.02614 1.0528,15.9296 L5.8528,14.3296 C5.8608,14.3272 5.8648,14.32 5.872,14.3176 C5.98219055,14.2797314 6.08250898,14.2176815 6.1656,14.136 L15.1024,5.1992 C15.6787109,4.62611069 16.001907,3.84634967 16.00001,3.0336 C16.0020855,2.22081505 15.678862,1.44098802 15.1024,0.868 Z M13.9712,4.068 L13.6,4.4392 L11.5312,2.3704 L11.9024,1.9992 C12.4552,1.4464 13.4184,1.4464 13.9712,1.9992 C14.248,2.276 14.4,2.6432 14.4,3.0336 C14.4,3.424 14.248,3.7912 13.9712,4.068 L13.9712,4.068 Z M2.7496,11.8512 L4.1192,13.2208 L2.0648,13.9056 L2.7496,11.8512 L2.7496,11.8512 Z M5.6,12.4392 L3.5312,10.3704 L10.4,3.5016 L12.4688,5.5704 L5.6,12.4392 Z"
        transform=""
      />
    </g>
  </svg>
);

EditIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

EditIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default EditIcon;
