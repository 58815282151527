/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_phone.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const PhoneIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M13.7227251,13.7409526 C10.0229156,13.8171431 7.10786797,12.7916193 5.04082035,10.7283812 C2.03815368,7.73180973 1.58939178,3.25866687 1.53377273,1.52457163 L4.71624892,1.52457163 L5.23662987,4.15009544 L4.2324394,4.65295259 C3.93224892,4.80304783 3.76539178,5.13295259 3.82177273,5.46361925 L3.82101083,5.45904783 C4.11586797,7.22666687 5.73948702,10.7451431 9.78062987,11.4186669 C10.1067251,11.4697145 10.435868,11.3081907 10.5859632,11.0102859 L11.0933918,10.0045716 L13.7219632,10.5302859 L13.7219632,13.7409526 L13.7227251,13.7409526 L13.7227251,13.7409526 Z M14.6339632,9.15809544 L10.8244394,8.39619068 C10.4897859,8.32771078 10.1506675,8.49149845 9.99624892,8.79619068 L9.99396321,8.80076211 L9.48882035,9.80114306 C6.77491559,9.09638116 5.77834416,6.7992383 5.45758225,5.7432383 L6.43662987,5.25333354 C6.74556797,5.09915409 6.91220718,4.75649635 6.84272511,4.41828592 L6.84348702,4.42285735 L6.08767749,0.613333539 C6.01689398,0.257306338 5.70476756,0.000654892212 5.34177273,1.03028582e-07 L0.769582254,1.03028582e-07 C0.365344359,-0.000208247778 0.0314565903,0.315613449 0.00920130149,0.719238301 L0.00920130149,0.721524016 C-0.00527488899,0.998095444 -0.297846318,7.54285735 3.95662987,11.7980954 C6.26139178,14.1028573 9.43396321,15.2670478 13.3996775,15.2670478 C13.7684394,15.2670478 14.1432965,15.2571431 14.5250108,15.2373335 C14.9296572,15.2158571 15.2466989,14.8814065 15.2465347,14.4761907 L15.2465347,9.90476211 C15.2465347,9.54340569 14.9924419,9.23185132 14.6385346,9.15885735 L14.6339632,9.15809544 Z"
        transform=""
      />
    </g>
  </svg>
);

PhoneIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

PhoneIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default PhoneIcon;
