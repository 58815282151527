import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ColorPalette } from "../StylingConstants";
import { StyledOverline } from "../Typography";

const StyledWrapper = styled(StyledOverline)`
  background: ${ColorPalette.white05};
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  margin-bottom: 8px;
`;

/**
 * Yuka v2.0
 * This is a component used to display the standard heading for a list of definitions
 *
 * @param {object} props
 *
 * @returns {Element}
 */
const DefinitionListHeader = props => (
  <StyledWrapper className={props.className}>
    <span>{props.title}</span>
    {props.action && <span>{props.action}</span>}
  </StyledWrapper>
);

DefinitionListHeader.propTypes = {
  title: PropTypes.node.isRequired,
  action: PropTypes.node,
  className: PropTypes.string,
};

DefinitionListHeader.defaultProps = {
  action: null,
  className: "",
};

// Wrap with styled so this can be used inline for styles
const StyledDefinitionListHeader = styled(DefinitionListHeader)``;

StyledDefinitionListHeader.displayName = "DefinitionListHeader";

/* @component */
export default StyledDefinitionListHeader;
