/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_circle_plus.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CirclePlusIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M8,0 C12.4112,0 16,3.5888 16,8 C16,12.4112 12.4112,16 8,16 C3.5888,16 0,12.4112 0,8 C0,3.5888 3.5888,0 8,0 Z M8,1.6 C4.4712,1.6 1.6,4.4712 1.6,8 C1.6,11.5288 4.4712,14.4 8,14.4 C11.5288,14.4 14.4,11.5288 14.4,8 C14.4,4.4712 11.5288,1.6 8,1.6 Z M8.0192,4.8032 C8.0536,4.8048 8.0624,4.8048 8.0968,4.8088 C8.4552,4.852 8.764,5.1624 8.8,5.5288 C8.8032,5.5632 8.8024,5.572 8.8032,5.6064 L8.8032,7.1968 L10.3968,7.1968 C10.4096,7.1968 10.4224,7.1968 10.4352,7.1976 C10.8272,7.2256 11.1696,7.5504 11.1984,7.9416 C11.2296,8.3776 10.8592,8.792 10.3968,8.8032 L8.8032,8.8032 L8.8032,10.4 C8.7952,10.7288 8.5856,11.0376 8.2848,11.1512 C7.8176,11.328 7.2232,10.9776 7.1976,10.4384 L7.1969,10.4192 L7.1969,10.4192 L7.1968,8.8032 L5.6032,8.8032 C5.1944,8.7936 4.8304,8.4552 4.8016,8.0584 C4.7712,7.6344 5.1184,7.2192 5.5648,7.1976 L5.584,7.1969 L5.584,7.1969 L7.1968,7.1968 L7.1968,5.6064 C7.1976,5.572 7.1968,5.5632 7.2,5.5288 C7.2352,5.1696 7.5384,4.8528 7.9032,4.8088 C7.9784,4.8 7.9952,4.8032 8.0192,4.8032 Z"
        transform=""
      />
    </g>
  </svg>
);

CirclePlusIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CirclePlusIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default CirclePlusIcon;
