/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_calculator.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CalculatorIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule=""
        d="M4.47571 1H11.8543C12.2457 1 12.621 1.15548 12.8978 1.43223C13.1745 1.70898 13.33 2.08433 13.33 2.47571V14.2814C13.33 14.6728 13.1745 15.0482 12.8978 15.3249C12.621 15.6017 12.2457 15.7571 11.8543 15.7571H4.47571C4.08433 15.7571 3.70898 15.6017 3.43223 15.3249C3.15548 15.0482 3 14.6728 3 14.2814V2.47571C3 2.08433 3.15548 1.70898 3.43223 1.43223C3.70898 1.15548 4.08433 1 4.47571 1V1ZM4.47571 2.47571V5.42714H11.8543V2.47571H4.47571ZM4.47571 6.90286V8.37857H5.95143V6.90286H4.47571ZM7.42714 6.90286V8.37857H8.90286V6.90286H7.42714ZM10.3786 6.90286V8.37857H11.8543V6.90286H10.3786ZM4.47571 9.85429V11.33H5.95143V9.85429H4.47571ZM7.42714 9.85429V11.33H8.90286V9.85429H7.42714ZM10.3786 9.85429V11.33H11.8543V9.85429H10.3786ZM4.47571 12.8057V14.2814H5.95143V12.8057H4.47571ZM7.42714 12.8057V14.2814H8.90286V12.8057H7.42714ZM10.3786 12.8057V14.2814H11.8543V12.8057H10.3786Z"
        transform=""
      />
    </g>
  </svg>
);

CalculatorIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CalculatorIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default CalculatorIcon;
