/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_analytics.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const AnalyticsIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.2,14.4 C15.6418278,14.4 16,14.7581722 16,15.2 C16,15.6418278 15.6418278,16 15.2,16 L0.8,16 C0.3581722,16 0,15.6418278 0,15.2 C0,14.7581722 0.3581722,14.4 0.8,14.4 L15.2,14.4 Z M15.2,0 C15.6418278,0 16,0.3581722 16,0.8 L16,12 C16,12.4418278 15.6418278,12.8 15.2,12.8 L0.8,12.8 C0.3581722,12.8 0,12.4418278 0,12 L0,7.2 C0,6.7581722 0.3581722,6.4 0.8,6.4 L4.8,6.4 L4.8,4 C4.8,3.5581722 5.1581722,3.2 5.6,3.2 L9.6,3.2 L9.6,0.8 C9.6,0.3581722 9.9581722,0 10.4,0 L15.2,0 Z M11.2,11.2 L14.4,11.2 L14.4,1.6 L11.2,1.6 L11.2,11.2 Z M6.4,11.2 L9.6,11.2 L9.6,4.8 L6.4,4.8 L6.4,11.2 Z M1.6,11.2 L4.8,11.2 L4.8,8 L1.6,8 L1.6,11.2 Z"
        transform=""
      />
    </g>
  </svg>
);

AnalyticsIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

AnalyticsIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default AnalyticsIcon;
