/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_select.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const SelectIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule=""
        d="M11,6 L8,3 L5,6 L11,6 Z M10.9,10 L8,12.9 L5.1,10 L10.9,10 L10.9,10 Z"
        transform=""
      />
    </g>
  </svg>
);

SelectIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

SelectIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default SelectIcon;
