/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_add_image.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const AddImageIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.2,8 C14.7581722,8 14.4,8.3581722 14.4,8.8 L14.4,9.2176 L11.4968,8.0568 C11.264632,7.96340004 11.0020412,7.98473924 10.788,8.1144 L7.2224,10.2536 L5.244,8.9344 C4.98679346,8.76196716 4.65297909,8.75416776 4.388,8.9144 L1.6,10.5864 L1.6,1.6 L7.2,1.6 C7.6418278,1.6 8,1.2418278 8,0.8 C8,0.3581722 7.6418278,0 7.2,0 L0.8,0 C0.3581722,0 0,0.3581722 0,0.8 L0,15.2 C0,15.6418278 0.3581722,16 0.8,16 L15.2,16 C15.6418278,16 16,15.6418278 16,15.2 L16,8.8 C16,8.3581722 15.6418278,8 15.2,8 Z M10.4,4 L12,4 L12,5.6 C12,6.0418278 12.3581722,6.4 12.8,6.4 C13.2418278,6.4 13.6,6.0418278 13.6,5.6 L13.6,4 L15.2,4 C15.6418278,4 16,3.6418278 16,3.2 C16,2.7581722 15.6418278,2.4 15.2,2.4 L13.6,2.4 L13.6,0.8 C13.6,0.3581722 13.2418278,0 12.8,0 C12.3581722,0 12,0.3581722 12,0.8 L12,2.4 L10.4,2.4 C9.9581722,2.4 9.6,2.7581722 9.6,3.2 C9.6,3.6418278 9.9581722,4 10.4,4 M7.2,5.6 C7.2,6.0418278 6.8418278,6.4 6.4,6.4 C5.9581722,6.4 5.6,6.0418278 5.6,5.6 C5.6,5.1581722 5.9581722,4.8 6.4,4.8 C6.8418278,4.8 7.2,5.1581722 7.2,5.6 M4,5.6 C4,6.9232 5.0768,8 6.4,8 C7.7232,8 8.8,6.9232 8.8,5.6 C8.8,4.2768 7.7232,3.2 6.4,3.2 C5.0768,3.2 4,4.2768 4,5.6 M1.6,14.4 L1.6,12.4528 L4.7776,10.5464 L6.756,11.8656 C7.0136,12.036 7.3456,12.0456 7.612,11.8856 L11.2712,9.6896 L14.4,10.9416 L14.4,14.4 L1.6,14.4 Z"
        transform=""
      />
    </g>
  </svg>
);

AddImageIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

AddImageIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default AddImageIcon;
