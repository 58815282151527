/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_add_note.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const AddNoteIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M7.2,7.2 C7.6418278,7.2 8,7.5581722 8,8 C8,8.4418278 7.6418278,8.8 7.2,8.8 L4,8.8 C3.5581722,8.8 3.2,8.4418278 3.2,8 C3.2,7.5581722 3.5581722,7.2 4,7.2 L7.2,7.2 Z M7.2,10.4 C7.6418278,10.4 8,10.7581722 8,11.2 C8,11.6418278 7.6418278,12 7.2,12 L4,12 C3.5581722,12 3.2,11.6418278 3.2,11.2 C3.2,10.7581722 3.5581722,10.4 4,10.4 L7.2,10.4 Z M15.2,8.8 C15.6418278,8.8 16,9.1581722 16,9.6 C16,10.0418278 15.6418278,10.4 15.2,10.4 L13.6,10.4 L13.6,12 C13.6,12.4418278 13.2418278,12.8 12.8,12.8 C12.3581722,12.8 12,12.4418278 12,12 L12,10.4 L10.4,10.4 C9.9581722,10.4 9.6,10.0418278 9.6,9.6 C9.6,9.1581722 9.9581722,8.8 10.4,8.8 L12,8.8 L12,7.2 C12,6.7581722 12.3581722,6.4 12.8,6.4 C13.2418278,6.4 13.6,6.7581722 13.6,7.2 L13.6,8.8 L15.2,8.8 Z M12,14.4 C12.4418278,14.4 12.8,14.7581722 12.8,15.2 C12.8,15.6418278 12.4418278,16 12,16 L0.8,16 C0.3581722,16 0,15.6418278 0,15.2 L0,0.8 C0,0.3581722 0.3581722,0 0.8,0 L8.8,0 C9.012,0 9.216,0.084 9.3656,0.2344 L12.5656,3.4344 C12.7676694,3.63646942 12.8465865,3.93099201 12.772624,4.20702397 C12.6986614,4.48305593 12.4830559,4.69866143 12.207024,4.77262397 C11.930992,4.84658651 11.6364694,4.76766942 11.4344,4.5656 L8.4688,1.6 L1.6,1.6 L1.6,14.4 L12,14.4 Z M4,5.6 C3.5581722,5.6 3.2,5.2418278 3.2,4.8 C3.2,4.3581722 3.5581722,4 4,4 L4.8,4 C5.2418278,4 5.6,4.3581722 5.6,4.8 C5.6,5.2418278 5.2418278,5.6 4.8,5.6 L4,5.6 Z"
        transform=""
      />
    </g>
  </svg>
);

AddNoteIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

AddNoteIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default AddNoteIcon;
