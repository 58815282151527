/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_globe.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const GlobeIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M11.968,8.8 L14.344,8.8 C14.0383429,11.1933471 12.414544,13.2122745 10.1424,14.024 C10.9624,12.852 11.8048,11.116 11.968,8.8 Z M4.032,8.8 C4.1952,11.116 5.0384,12.852 5.8584,14.024 C3.58572127,13.212832 1.96144311,11.1936931 1.656,8.8 L4.032,8.8 L4.032,8.8 Z M4.032,7.2 L1.656,7.2 C1.96144311,4.80630689 3.58572127,2.78716804 5.8584,1.976 C5.0384,3.1472 4.1944,4.8832 4.032,7.2 L4.032,7.2 Z M5.6336,7.2 C5.8624,4.4048 7.2472,2.6416 8,1.8768 C8.752,2.6416 10.1376,4.4056 10.3672,7.2 L5.6336,7.2 Z M5.6336,8.8 L10.3672,8.8 C10.1384,11.5952 8.7512,13.36 7.9992,14.1224 C7.2472,13.3576 5.8624,11.5944 5.6336,8.8 L5.6336,8.8 Z M11.9528,7.2 C11.788,4.884 10.9584,3.1416 10.1392,1.976 C12.4127037,2.7865912 14.0379617,4.80579496 14.344,7.2 L11.952,7.2 L11.9528,7.2 Z M0,8 C0,12.4104 3.5888,16 8,16 C12.4112,16 16,12.4104 16,8 C16,3.5888 12.4112,0 8,0 C3.5888,0 0,3.5888 0,8 Z"
        transform=""
      />
    </g>
  </svg>
);

GlobeIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

GlobeIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default GlobeIcon;
