/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_back.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const BackIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.2,7.05714286 L2.9288,7.05714286 L7.7264,2.86034286 C8.05636947,2.56896536 8.08893692,2.0657501 7.79927793,1.73427104 C7.50961894,1.40279199 7.00657969,1.36761019 6.6736,1.65554286 L0.2736,7.25554286 C0.2672,7.26114286 0.2648,7.26994286 0.2584,7.27554286 C0.201633944,7.33190332 0.153875461,7.39666058 0.1168,7.46754286 C0.1024,7.49154286 0.0808,7.51074286 0.0696,7.53714286 C-0.022431543,7.74110872 -0.022431543,7.97477699 0.0696,8.17874286 C0.0808,8.20514286 0.1024,8.22434286 0.1168,8.24914286 C0.1568,8.31874286 0.2,8.38514286 0.2584,8.44034286 C0.2648,8.44594286 0.2672,8.45474286 0.2736,8.46034286 L6.6736,14.0603429 C6.83300162,14.2007655 7.04187725,14.2717224 7.25382946,14.2574521 C7.46578168,14.2431817 7.66326048,14.1448657 7.8024,13.9843429 C8.09311658,13.6516445 8.05909055,13.1462684 7.7264,12.8555429 L2.9288,8.65714286 L15.2,8.65714286 C15.6418278,8.65714286 16,8.29897066 16,7.85714286 C16,7.41531506 15.6418278,7.05714286 15.2,7.05714286"
        transform=""
      />
    </g>
  </svg>
);

BackIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

BackIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default BackIcon;
