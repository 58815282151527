/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_folder_down.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const FolderDownIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.2,1.60000167 L7.5312,1.60000167 L6.1656,0.234401674 C6.015806,0.0840629468 5.81222601,-0.000306131655 5.6,8.34718994e-07 L0.8,8.34718994e-07 C0.3581722,8.34718994e-07 0,0.358173874 0,0.800001674 L0,15.2000017 C0,15.6418295 0.3581722,16.0000008 0.8,16.0000008 L15.2,16.0000008 C15.6418278,16.0000008 16,15.6418295 16,15.2000017 L16,2.40000167 C16,1.95817387 15.6418278,1.60000167 15.2,1.60000167 Z M7.2,9.26880167 L6.1656,8.23440167 C5.96353058,8.03233225 5.66900799,7.95341515 5.39297602,8.02737769 C5.11694406,8.10134023 4.90133855,8.31694573 4.82737601,8.5929777 C4.75341347,8.86900966 4.83233057,9.16353226 5.0344,9.36560167 L7.4344,11.7656017 C7.5841081,11.9161691 7.78767229,12.0008296 8,12.0008296 C8.21232771,12.0008296 8.4158919,11.9161691 8.5656,11.7656017 L10.9656,9.36560167 C11.1676694,9.16353226 11.2465865,8.86900966 11.172624,8.59297769 C11.0986615,8.31694573 10.8830559,8.10134022 10.607024,8.02737768 C10.330992,7.95341514 10.0364694,8.03233224 9.8344,8.23440167 L8.8,9.26880167 L8.8,6.40000167 C8.8,5.95817387 8.4418278,5.60000167 8,5.60000167 C7.5581722,5.60000167 7.2,5.95817387 7.2,6.40000167 L7.2,9.26880167 Z M14.4,14.4000017 L1.6,14.4000017 L1.6,1.60000167 L5.2688,1.60000167 L6.6344,2.96560167 C6.784194,3.1159404 6.98777399,3.20030948 7.2,3.20000167 L14.4,3.20000167 L14.4,14.4000017 Z"
        transform=""
      />
    </g>
  </svg>
);

FolderDownIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

FolderDownIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default FolderDownIcon;
