/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_folder.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const FolderIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M1.6,14.4 L14.4,14.4 L14.4,3.2 L7.2,3.2 C6.98777399,3.20030781 6.784194,3.11593873 6.6344,2.9656 L5.2688,1.6 L1.6,1.6 L1.6,14.4 Z M15.2,16 L0.8,16 C0.3581722,16 0,15.6418278 0,15.2 L0,0.8 C0,0.3581722 0.3581722,0 0.8,0 L5.6,0 C5.8128,0 6.016,0.084 6.1656,0.2344 L7.5312,1.6 L15.2,1.6 C15.6418278,1.6 16,1.9581722 16,2.4 L16,15.2 C16,15.6418278 15.6418278,16 15.2,16 Z"
        transform=""
      />
    </g>
  </svg>
);

FolderIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

FolderIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default FolderIcon;
