import { body1, overline, FontColors } from "../Typography";

const SIZE_SMALL = "small";
const SIZE_LARGE = "large";

const TYPE_PRIMARY = "primary";
const TYPE_SECONDARY = "secondary";

// Button styles based on Yuka v2 (May 2020) style guide
const ButtonStyles = {
  BUY: {
    $size: SIZE_SMALL,
    $buttonType: TYPE_SECONDARY,
    $typography: { ...overline, ...FontColors.lightGreen },
  },
  SELL: {
    $size: SIZE_SMALL,
    $buttonType: TYPE_SECONDARY,
    $typography: { ...overline, ...FontColors.normalRed },
  },
  SECONDARY: { $size: SIZE_SMALL, $buttonType: TYPE_SECONDARY, $typography: overline },
  PRIMARY: { $size: SIZE_SMALL, $buttonType: TYPE_PRIMARY, $typography: overline },
  LARGE_SECONDARY: {
    $size: SIZE_LARGE,
    $buttonType: TYPE_SECONDARY,
    $typography: body1,
  },
  LARGE_PRIMARY: { $size: SIZE_LARGE, $buttonType: TYPE_PRIMARY, $typography: body1 },
};

export default ButtonStyles;
export { SIZE_LARGE, TYPE_PRIMARY };
