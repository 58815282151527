/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_legal.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const LegalIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M13.6,0 L4.8,0 C3.0326888,0 1.6,1.4326888 1.6,3.2 L1.6,11.2 L0.8,11.2 C0.3581722,11.2 0,11.5581722 0,12 L0,12.8 C0,14.5673112 1.4326888,16 3.2,16 L10.4,16 C11.7254834,16 12.8,14.9254834 12.8,13.6 L12.8,4.8 L13.6,4.8 C14.9254834,4.8 16,3.7254834 16,2.4 C16,1.0745166 14.9254834,0 13.6,0 Z M3.2,14.4 C2.3163444,14.4 1.6,13.6836556 1.6,12.8 L8,12.8 L8,13.6 C7.99959581,13.8724531 8.0455864,14.1429859 8.136,14.4 L3.2,14.4 L3.2,14.4 Z M11.2,13.6 C11.2,14.0418278 10.8418278,14.4 10.4,14.4 C9.9581722,14.4 9.6,14.0418278 9.6,13.6 L9.6,12 C9.6,11.5581722 9.2418278,11.2 8.8,11.2 L3.2,11.2 L3.2,3.2 C3.2,2.3163444 3.9163444,1.6 4.8,1.6 L11.344,1.6 C11.2496164,1.85620204 11.2008787,2.12696707 11.2,2.4 L11.2,13.6 L11.2,13.6 Z M13.6,3.2 L12.8,3.2 L12.8,2.4 C12.8,1.9581722 13.1581722,1.6 13.6,1.6 C14.0418278,1.6 14.4,1.9581722 14.4,2.4 C14.4,2.8418278 14.0418278,3.2 13.6,3.2 Z M9.6,4 C9.6,4.4418278 9.2418278,4.8 8.8,4.8 L5.6,4.8 C5.1581722,4.8 4.8,4.4418278 4.8,4 C4.8,3.5581722 5.1581722,3.2 5.6,3.2 L8.8,3.2 C9.2418278,3.2 9.6,3.5581722 9.6,4 Z M9.6,7.2 C9.6,7.6418278 9.2418278,8 8.8,8 L5.6,8 C5.1581722,8 4.8,7.6418278 4.8,7.2 C4.8,6.7581722 5.1581722,6.4 5.6,6.4 L8.8,6.4 C9.2418278,6.4 9.6,6.7581722 9.6,7.2 Z"
        transform=""
      />
    </g>
  </svg>
);

LegalIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

LegalIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default LegalIcon;
