import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import { ColorPalette } from "../StylingConstants";
import { FontColors, body1 } from "../Typography";

const StyledTab = styled.div`
  ${body1}
  ${props => (props.$active ? FontColors.brightest : FontColors.light)}
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  height: 44px;

  ${props =>
    props.$active
      ? `
    border-bottom: 2px solid ${ColorPalette.violetNormal};
    `
      : css`
          &:hover {
            ${FontColors.bright}
          }
        `}

  .notification-dot {
    margin-right: 8px;
  }

  & + & {
    margin-left: 32px;
  }
`;

/**
 * Yuka v2.0
 * This renders a clickable tab to switch between active tabs within a layout.
 * These styles are aligned with ``NavLink``
 *
 * @param {object} props
 *
 * @returns {React.Component}
 */
const TabItem = props => (
  <StyledTab $active={props.active} onClick={props.handleTabClick}>
    {props.showNotification && <div className="notification-dot" />}
    {props.children}
  </StyledTab>
);

TabItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  handleTabClick: PropTypes.func.isRequired,
  showNotification: PropTypes.bool,
};

TabItem.defaultProps = {
  active: false,
  showNotification: false,
};

export default TabItem;
