/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_bid.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const BidIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule=""
        d="M15.2,0 L0.8,0 C0.3581722,0 0,0.3581722 0,0.8 L0,7.2 C0,7.6418278 0.3581722,8 0.8,8 C1.2418278,8 1.6,7.6418278 1.6,7.2 L1.6,1.6 L14.4,1.6 L14.4,14.4 L8.8,14.4 C8.3581722,14.4 8,14.7581722 8,15.2 C8,15.6418278 8.3581722,16 8.8,16 L15.2,16 C15.6418278,16 16,15.6418278 16,15.2 L16,0.8 C16,0.3581722 15.6418278,0 15.2,0 M1.6,14.4 L4.8,14.4 L4.8,11.2 L1.6,11.2 L1.6,14.4 Z M5.6,9.6 L0.8,9.6 C0.3581722,9.6 0,9.9581722 0,10.4 L0,15.2 C0,15.6418278 0.3581722,16 0.8,16 L5.6,16 C6.0418278,16 6.4,15.6418278 6.4,15.2 L6.4,10.4 C6.4,9.9581722 6.0418278,9.6 5.6,9.6 Z M12,8.8 C12.4418278,8.8 12.8,8.4418278 12.8,8 C12.8,7.5581722 12.4418278,7.2 12,7.2 L9.9312,7.2 L12.5656,4.5656 C12.8779723,4.25322775 12.8779723,3.74677225 12.5656,3.4344 C12.2532277,3.12202775 11.7467723,3.12202775 11.4344,3.4344 L8.8,6.0688 L8.8,4 C8.8,3.5581722 8.4418278,3.2 8,3.2 C7.5581722,3.2 7.2,3.5581722 7.2,4 L7.2,8 C7.2,8.4418278 7.5581722,8.8 8,8.8 L12,8.8 Z"
        transform=""
      />
    </g>
  </svg>
);

BidIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

BidIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default BidIcon;
