/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_face_joy.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const FaceJoyIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M8,0 C3.5888,0 0,3.5888 0,8 C0,12.4112 3.5888,16 8,16 C12.4112,16 16,12.4112 16,8 C16,3.5888 12.4112,0 8,0 M8,14.4 C4.4712,14.4 1.6,11.5288 1.6,8 C1.6,4.4712 4.4712,1.6 8,1.6 C11.5288,1.6 14.4,4.4712 14.4,8 C14.4,11.5288 11.5288,14.4 8,14.4 M5.6,5.6 C6.0418278,5.6 6.4,5.2418278 6.4,4.8 C6.4,4.3581722 6.0418278,4 5.6,4 C5.1581722,4 4.8,4.3581722 4.8,4.8 C4.8,5.2418278 5.1581722,5.6 5.6,5.6 M10.4,5.6 C10.8418278,5.6 11.2,5.2418278 11.2,4.8 C11.2,4.3581722 10.8418278,4 10.4,4 C9.9581722,4 9.6,4.3581722 9.6,4.8 C9.6,5.2418278 9.9581722,5.6 10.4,5.6 M8,10.4 C6.98320897,10.3987595 6.07708286,9.75816132 5.7368,8.8 L10.2632,8.8 C9.92291714,9.75816132 9.01679103,10.3987595 8,10.4 M11.2,7.2 L4.8,7.2 C4.3581722,7.2 4,7.5581722 4,8 C4,10.2056 5.7944,12 8,12 C10.2056,12 12,10.2056 12,8 C12,7.5581722 11.6418278,7.2 11.2,7.2"
        transform=""
      />
    </g>
  </svg>
);

FaceJoyIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

FaceJoyIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default FaceJoyIcon;
