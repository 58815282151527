/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_folder_plus.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const FolderPlusIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.2,1.60000167 L7.5312,1.60000167 L6.1656,0.234401674 C6.015806,0.0840629468 5.81222601,-0.000306131655 5.6,8.34718994e-07 L0.8,8.34718994e-07 C0.3581722,8.34718994e-07 0,0.358173874 0,0.800001674 L0,15.2000017 C0,15.6418295 0.3581722,16.0000008 0.8,16.0000008 L15.2,16.0000008 C15.6418278,16.0000008 16,15.6418295 16,15.2000017 L16,2.40000167 C16,1.95817387 15.6418278,1.60000167 15.2,1.60000167 Z M7.2,8.00000167 L5.6,8.00000167 C5.1581722,8.00000167 4.8,8.35817387 4.8,8.80000167 C4.8,9.24182947 5.1581722,9.60000167 5.6,9.60000167 L7.2,9.60000167 L7.2,11.2000017 C7.2,11.6418295 7.5581722,12.0000017 8,12.0000017 C8.4418278,12.0000017 8.8,11.6418295 8.8,11.2000017 L8.8,9.60000167 L10.4,9.60000167 C10.8418278,9.60000167 11.2,9.24182947 11.2,8.80000167 C11.2,8.35817387 10.8418278,8.00000167 10.4,8.00000167 L8.8,8.00000167 L8.8,6.40000167 C8.8,5.95817387 8.4418278,5.60000167 8,5.60000167 C7.5581722,5.60000167 7.2,5.95817387 7.2,6.40000167 L7.2,8.00000167 Z M14.4,14.4000017 L1.6,14.4000017 L1.6,1.60000167 L5.2688,1.60000167 L6.6344,2.96560167 C6.784194,3.1159404 6.98777399,3.20030948 7.2,3.20000167 L14.4,3.20000167 L14.4,14.4000017 Z"
        transform=""
      />
    </g>
  </svg>
);

FolderPlusIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

FolderPlusIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default FolderPlusIcon;
