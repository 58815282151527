/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_arrow_up.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ArrowUpIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.5990054,7.19689481 L3.32780543,7.19689481 L8.12540543,3.00009481 C8.4553749,2.70871732 8.48794235,2.20550205 8.19828336,1.874023 C7.90862437,1.54254394 7.40558512,1.50736215 7.07260543,1.79529481 L0.67260543,7.39529481 C0.66620543,7.40089481 0.66380543,7.40969481 0.65740543,7.41529481 C0.600639374,7.47165527 0.552880891,7.53641254 0.51580543,7.60729481 C0.50140543,7.63129481 0.47980543,7.65049481 0.46860543,7.67689481 C0.376573887,7.88086068 0.376573887,8.11452895 0.46860543,8.31849481 C0.47980543,8.34489481 0.50140543,8.36409481 0.51580543,8.38889481 C0.55580543,8.45849481 0.59900543,8.52489481 0.65740543,8.58009481 C0.66380543,8.58569481 0.66620543,8.59449481 0.67260543,8.60009481 L7.07260543,14.2000948 C7.23200705,14.3405174 7.44088268,14.4114744 7.65283489,14.397204 C7.86478711,14.3829337 8.06226591,14.2846176 8.20140543,14.1240948 C8.492122,13.7913964 8.45809598,13.2860204 8.12540543,12.9952948 L3.32780543,8.79689481 L15.5990054,8.79689481 C16.0408332,8.79689481 16.3990054,8.43872261 16.3990054,7.99689481 C16.3990054,7.55506701 16.0408332,7.19689481 15.5990054,7.19689481"
        transform="translate(8.399294, 7.999712) rotate(-270.000000) translate(-8.399294, -7.999712) "
      />
    </g>
  </svg>
);

ArrowUpIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ArrowUpIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default ArrowUpIcon;
