/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_security_unprotected.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const SecurityUnprotectedIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M7.2,4.8 C7.2,4.3581722 7.5581722,4 8,4 C8.4418278,4 8.8,4.3581722 8.8,4.8 L8.8,8 C8.8,8.4418278 8.4418278,8.8 8,8.8 C7.5581722,8.8 7.2,8.4418278 7.2,8 L7.2,4.8 Z M7.2,11.2 C7.2,10.7581722 7.5581722,10.4 8,10.4 C8.4418278,10.4 8.8,10.7581722 8.8,11.2 C8.8,11.6418278 8.4418278,12 8,12 C7.5581722,12 7.2,11.6418278 7.2,11.2 Z M15.272,2.1424 C16.3664,5.3664 16.224,8.4024 14.8616,10.9184 C13.5432,13.352 11.0536,15.2416 8.1992,15.9744 C8.06880931,16.0081029 7.93199069,16.0081029 7.8016,15.9744 C4.9472,15.2416 2.4576,13.352 1.1392,10.9184 C-0.224,8.4032 -0.3664,5.3672 0.7272,2.1432 C0.837251365,1.81850418 1.14196085,1.60002715 1.4848,1.6 C1.516,1.6 4.6896,1.5752 7.6392,0.0856 C7.8664,-0.028 8.1344,-0.028 8.3608,0.0856 C11.3256,1.5816 14.4832,1.6 14.5144,1.6 C14.8573224,1.5995679 15.1623792,1.81774137 15.2728,2.1424 L15.272,2.1424 Z M13.4536,10.1576 C14.528,8.1736 14.688,5.7696 13.9216,3.176 C12.8336,3.1056 10.4152,2.8208 8.0008,1.6896 C5.5848,2.8208 3.1672,3.1056 2.0784,3.176 C1.3136,5.7696 1.472,8.1744 2.5464,10.1576 C3.6264,12.1496 5.6536,13.7128 8.0008,14.372 C10.3464,13.7128 12.3744,12.1496 13.4536,10.1576 Z"
        transform=""
      />
    </g>
  </svg>
);

SecurityUnprotectedIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

SecurityUnprotectedIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default SecurityUnprotectedIcon;
