import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import ButtonStyles, { SIZE_LARGE, TYPE_PRIMARY } from "./ButtonStyles";

import { FontColors } from "../Typography";
import { ColorPalette } from "../StylingConstants";

const baseStyles = css`
  ${props => props.$typography}
  ${FontColors.brightest}
  & + & {
    // rows of buttons are spaced out
    margin-left: 12px;
  }
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  min-width: 0;
  outline: none;
  transition: all 0.1s cubic-bezier(0.33, 0, 0.2, 1);

  svg:first-child {
    margin-right: 8px;
  }

  svg:last-child {
    margin-left: 8px;
  }

  ${props =>
    props.$fullWidth
      ? `
    padding: 0;
    width: 100%;
  `
      : `
    padding: 0 12px;
  `}

  ${props => (props.$size === SIZE_LARGE ? `height: 32px;` : `height: 26px;`)}

  ${props =>
    props.$buttonType === TYPE_PRIMARY
      ? `
    background: ${ColorPalette.violetNormal};

    &:hover {
      background: #838fcf; // opacity change of normal violet
    }

    &:active${props.$active ? ", &" : ""} {
      background: #a0a9da; // opacity change of normal violet
    }

    &:disabled {
      background: ${ColorPalette.white10};
      color: rgba(255, 255, 255, 0.2);
    }
  `
      : css`
          border: 1px solid ${ColorPalette.faintWhite};

          &:hover {
            background: rgba(197, 202, 233, 0.15);
            &:not(:disabled) {
              color: ${props => props.$typography?.color || ColorPalette.violetLight};
            }
          }

          &:active${props.$active ? ", &" : ""} {
            background-color: rgba(197, 202, 233, 0.3);
            &:not(:disabled) {
              color: ${props => props.$typography?.color || ColorPalette.violetLight};
            }
          }

          &:disabled {
            border-color: ${ColorPalette.minWhite};
            background: transparent;
            color: rgba(255, 255, 255, 0.2);
          }
        `}

  &:disabled {
    color: ${ColorPalette.minWhite};
  }

  & svg:last-child path {
    fill: ${ColorPalette.darkWhite};
  }
`;

const StyledLink = styled(Link)`
  ${baseStyles}
`;
const StyledA = styled.a`
  ${baseStyles}
`;
const StyledButton = styled.button`
  ${baseStyles}
`;

/**
 * Yuka v2.0
 * Wrapper component for buttons.
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const Button = props => {
  // Backwards compatible with legacy buttons which used `style` prop
  const buttonStyle = props.buttonStyle || props.style || ButtonStyles.SECONDARY; // eslint-disable-line react/prop-types
  let className = `btn ${props.className}`.trim();

  if (props.to) {
    return (
      <StyledLink
        {...buttonStyle}
        $fullWidth={props.fullWidth}
        $active={props.active}
        className={className}
        rel={props.rel}
        target={props.target}
        to={props.to}
      >
        <span>{props.children}</span>
      </StyledLink>
    );
  }
  if (props.href) {
    return (
      <StyledA
        {...buttonStyle}
        className={className}
        download={props.download}
        href={props.href}
        rel={props.rel}
        target={props.target}
        $fullWidth={props.fullWidth}
        $active={props.active}
      >
        <span>{props.children}</span>
      </StyledA>
    );
  }
  return (
    <StyledButton
      {...buttonStyle}
      className={className}
      disabled={props.disabled}
      onClick={props.onClick}
      type={props.type} // eslint-disable-line react/button-has-type
      $fullWidth={props.fullWidth}
      $active={props.active}
    >
      <span>{props.children}</span>
    </StyledButton>
  );
};

Button.propTypes = {
  /** force button active state */
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /** force button disabled state */
  disabled: PropTypes.bool,
  /** use only with href */
  download: PropTypes.bool,
  /** fill 100% of parent width */
  fullWidth: PropTypes.bool,
  /** renders an anchor element. Do not use with the to prop */
  href: PropTypes.string,
  /** do not use with href or to */
  onClick: PropTypes.func,
  buttonStyle: PropTypes.oneOf(Object.values(ButtonStyles)),
  /** use with href or to */
  target: PropTypes.string,
  /** renders a react-router Link. Do not use with the href prop */
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** do not use with href or to */
  type: PropTypes.string,
  /** always use with target */
  rel: PropTypes.string,
};

Button.defaultProps = {
  active: false,
  className: "",
  disabled: false,
  download: false,
  fullWidth: false,
  href: null,
  onClick: _.noop,
  buttonStyle: null,
  target: null,
  to: null,
  type: "button",
  rel: null,
};

export default Button;
