/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_accept.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const AcceptIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M5.6,14.2571433 C5.38779156,14.2573621 5.18424424,14.1730065 5.0344,14.0227429 L0.2344,9.22274286 C0.0323305723,9.02067344 -0.0465865262,8.72615084 0.0273760127,8.45011888 C0.101338552,8.17408692 0.316944058,7.95848141 0.592976022,7.88451887 C0.869007986,7.81055633 1.16353058,7.88947343 1.3656,8.09154286 L5.5544,12.2803429 L14.5928,1.73634286 C14.8799881,1.40099556 15.3846527,1.36195479 15.72,1.64914286 C16.0553473,1.93633093 16.0943881,2.44099556 15.8072,2.77634286 L6.2072,13.9763429 C6.06237935,14.1460111 5.85329524,14.2475083 5.6304,14.2563429 L5.6,14.2563429"
        transform=""
      />
    </g>
  </svg>
);

AcceptIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

AcceptIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default AcceptIcon;
