/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_cross.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CrossIcon = ({ className, color, size }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule=""
        d="M9.23710025,7.99985589 L14.7434502,2.49350598 C15.0851057,2.15185039 15.0851057,1.59791722 14.7434502,1.25626162 C14.4017946,0.914606027 13.8478614,0.914606027 13.5062058,1.25626162 L7.99985589,6.76261153 L2.49350598,1.25626162 C2.27249357,1.03524921 1.95036095,0.94893403 1.64845237,1.02983019 C1.34654379,1.11072635 1.11072635,1.34654379 1.02983019,1.64845237 C0.94893403,1.95036095 1.03524921,2.27249357 1.25626162,2.49350598 L6.76261153,7.99985589 L1.25626162,13.5062058 C0.914606027,13.8478614 0.914606027,14.4017946 1.25626162,14.7434502 C1.59791722,15.0851057 2.15185039,15.0851057 2.49350598,14.7434502 L7.99985589,9.23710025 L13.5062058,14.7434502 C13.6699483,14.9081325 13.8925956,15.0007295 14.124828,15.0007295 C14.3570603,15.0007295 14.5797077,14.9081325 14.7434502,14.7434502 C14.9077012,14.5794781 15,14.3569168 15,14.124828 C15,13.8927391 14.9077012,13.6701778 14.7434502,13.5062058 L9.23710025,7.99985589 Z"
        transform=""
      />
    </g>
  </svg>
);

CrossIcon.propTypes = {
  className: PropTypes.string,
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CrossIcon.defaultProps = {
  className: "",
  color: "#ffffff",
  size: 16,
};

export default CrossIcon;
