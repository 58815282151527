/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_cards.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CardsIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M6.4,8.8 L0.8,8.8 C0.3581722,8.8 0,9.1581722 0,9.6 L0,15.2 C0,15.6418278 0.3581722,16 0.8,16 L6.4,16 C6.8418278,16 7.2,15.6418278 7.2,15.2 L7.2,9.6 C7.2,9.1581722 6.8418278,8.8 6.4,8.8 Z M5.6,14.4 L1.6,14.4 L1.6,10.4 L5.6,10.4 L5.6,14.4 Z M6.4,0 L0.8,0 C0.3581722,0 0,0.3581722 0,0.8 L0,6.4 C0,6.8418278 0.3581722,7.2 0.8,7.2 L6.4,7.2 C6.8418278,7.2 7.2,6.8418278 7.2,6.4 L7.2,0.8 C7.2,0.3581722 6.8418278,0 6.4,0 Z M15.2,0 L9.6,0 C9.1581722,0 8.8,0.3581722 8.8,0.8 L8.8,6.4 C8.8,6.8418278 9.1581722,7.2 9.6,7.2 L15.2,7.2 C15.6418278,7.2 16,6.8418278 16,6.4 L16,0.8 C16,0.3581722 15.6418278,0 15.2,0 Z M5.6,5.6 L1.6,5.6 L1.6,1.6 L5.6,1.6 L5.6,5.6 Z M14.4,5.6 L10.4,5.6 L10.4,1.6 L14.4,1.6 L14.4,5.6 Z M15.2,8.8 L9.6,8.8 C9.1581722,8.8 8.8,9.1581722 8.8,9.6 L8.8,15.2 C8.8,15.6418278 9.1581722,16 9.6,16 L15.2,16 C15.6418278,16 16,15.6418278 16,15.2 L16,9.6 C16,9.1581722 15.6418278,8.8 15.2,8.8 Z M14.4,14.4 L10.4,14.4 L10.4,10.4 L14.4,10.4 L14.4,14.4 Z"
        transform=""
      />
    </g>
  </svg>
);

CardsIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CardsIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default CardsIcon;
