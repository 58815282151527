/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_profile.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ProfileIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M8,0 C12.4112,0 16,3.5888 16,8 C16,12.4104 12.4112,16 8,16 C3.5888,16 0,12.4104 0,8 C0,3.5888 3.5888,0 8,0 Z M12.4824,12.56 C13.7092238,11.3600043 14.4005181,9.7161253 14.4000003,8 C14.4000003,4.472 11.5288,1.6 8,1.6 C4.4712,1.6 1.6,4.472 1.6,8 C1.6,9.784 2.3352,11.3984 3.5168,12.56 C4.6408,11.8736 6.1952,11.2 8,11.2 C9.8056,11.2 11.3592,11.8728 12.4824,12.56 Z M4.9192,13.608 C6.83682577,14.6665308 9.16372423,14.6659266 11.0808,13.6064 C10.2256,13.1664 9.1712,12.8 8,12.8 C6.8296,12.8 5.7744,13.1656 4.9192,13.608 L4.9192,13.608 Z M8,3.2 C9.7648,3.2 11.2,4.6352 11.2,6.4 C11.2,8.1648 9.7648,9.6 8,9.6 C6.2352,9.6 4.8,8.1648 4.8,6.4 C4.8,4.6352 6.2352,3.2 8,3.2 Z M8,8 C8.8824,8 9.6,7.2824 9.6,6.4 C9.6,5.5176 8.8824,4.8 8,4.8 C7.1176,4.8 6.4,5.5176 6.4,6.4 C6.4,7.2824 7.1176,8 8,8 Z"
        transform=""
      />
    </g>
  </svg>
);

ProfileIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ProfileIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default ProfileIcon;
