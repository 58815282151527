/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_hashtag.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const HashtagIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M5.3384,10.3994104 L5.872,5.59941042 L10.6616,5.59941042 L10.1288,10.3994104 L5.3384,10.3994104 Z M15.2,10.3994104 L11.7384,10.3994104 L12.272,5.59941042 L15.2,5.59941042 C15.6418278,5.59941042 16,5.24123822 16,4.79941042 C16,4.35758262 15.6418278,3.99941042 15.2,3.99941042 L12.4496,3.99941042 L12.7952,0.887410424 C12.8438504,0.448504224 12.5276697,0.0531888717 12.0888,0.00421042449 C11.6506397,-0.0403775742 11.257481,0.274149371 11.2048,0.711410424 L10.84,3.99941042 L6.0496,3.99941042 L6.3952,0.887410424 C6.41855388,0.676507463 6.35716279,0.464966006 6.2245343,0.299330424 C6.09190581,0.133694841 5.89890614,0.0275356206 5.688,0.00421042449 C5.25000381,-0.0403560839 4.85707984,0.274267824 4.8048,0.711410424 L4.44,3.99941042 L0.8,3.99941042 C0.3581722,3.99941042 0,4.35758262 0,4.79941042 C0,5.24123822 0.3581722,5.59941042 0.8,5.59941042 L4.2616,5.59941042 L3.7288,10.3994104 L0.8,10.3994104 C0.3581722,10.3994104 0,10.7575826 0,11.1994104 C0,11.6412382 0.3581722,11.9994104 0.8,11.9994104 L3.5512,11.9994104 L3.2048,15.1114104 C3.15619895,15.5505873 3.47282318,15.9460094 3.912,15.9946104 C4.35117683,16.0432115 4.74659893,15.7265872 4.7952,15.2874104 L5.16,11.9994104 L9.9512,11.9994104 L9.6048,15.1114104 C9.55619896,15.5505872 9.87282318,15.9460093 10.312,15.9946104 C10.7511768,16.0432115 11.1465989,15.7265872 11.1952,15.2874104 L11.56,11.9994104 L15.2,11.9994104 C15.6418278,11.9994104 16,11.6412382 16,11.1994104 C16,10.7575826 15.6418278,10.3994104 15.2,10.3994104 Z"
        transform=""
      />
    </g>
  </svg>
);

HashtagIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

HashtagIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default HashtagIcon;
