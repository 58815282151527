/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_crown.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CrownIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule=""
        d="M11.4650344,7.71662553 C11.3661632,7.77917674 11.2350074,7.74487446 11.1805273,7.64196764 L9.09414187,3.79607723 L8.17706045,2.10517683 C8.10139366,1.96494106 7.89860668,1.96494106 7.8229399,2.10517683 L6.90484959,3.79607723 L4.81947302,7.64196764 C4.76398405,7.74487446 4.63383718,7.77917674 4.53496591,7.71662553 L0.308723804,5.0460925 C0.162434688,4.95327458 -0.0262278263,5.0773681 0.00302999688,5.24988837 L1.40538773,13.7164971 C1.42152997,13.8133505 1.50627677,13.8859907 1.60514804,13.8859907 L14.3948523,13.8859907 C14.4937236,13.8859907 14.5784704,13.8133505 14.5946126,13.7164971 L15.9969703,5.24988837 C16.0262282,5.0773681 15.8375657,4.95327458 15.6902677,5.0460925 L11.4650344,7.71662553 Z"
        transform=""
      />
    </g>
  </svg>
);

CrownIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CrownIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default CrownIcon;
