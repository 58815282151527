/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_email.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const EmailIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M1.6,12.8 L1.6,4.3312 L7.4344,10.1656 C7.5841081,10.3161674 7.78767229,10.4008279 8,10.4008279 C8.21232771,10.4008279 8.4158919,10.3161674 8.5656,10.1656 L14.4,4.3312 L14.4,12.8 L1.6,12.8 Z M13.2688,3.2 L8,8.4688 L2.7312,3.2 L13.2688,3.2 L13.2688,3.2 Z M15.2,1.6 L0.8,1.6 C0.3581722,1.6 0,1.9581722 0,2.4 L0,13.6 C0,14.0418278 0.3581722,14.4 0.8,14.4 L15.2,14.4 C15.6418278,14.4 16,14.0418278 16,13.6 L16,2.4 C16,1.9581722 15.6418278,1.6 15.2,1.6 Z"
        transform=""
      />
    </g>
  </svg>
);

EmailIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

EmailIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default EmailIcon;
