/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_search_history.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const SearchHistoryIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.7656,14.6344 L11.4496,10.3176 C12.3243445,9.19904334 12.7997083,7.81998126 12.8,6.4 C12.8,2.8712 9.9288,1.59872116e-14 6.4,1.59872116e-14 C2.8712,1.59872116e-14 2.66453526e-14,2.8712 2.66453526e-14,6.4 C2.66453526e-14,9.9288 2.8712,12.8 6.4,12.8 C7.81998126,12.7997083 9.19904334,12.3243445 10.3176,11.4496 L14.6344,15.7656 C14.7841081,15.9161674 14.9876723,16.0008279 15.2,16.0008279 C15.4123277,16.0008279 15.6158919,15.9161674 15.7656,15.7656 C15.9157731,15.6156821 16.0001609,15.4121965 16.0001609,15.2 C16.0001609,14.9878035 15.9157731,14.7843179 15.7656,14.6344 M6.4,11.2 C3.7528,11.2 1.6,9.0472 1.6,6.4 C1.6,3.7528 3.7528,1.6 6.4,1.6 C9.0472,1.6 11.2,3.7528 11.2,6.4 C11.2,9.0472 9.0472,11.2 6.4,11.2 M7.2,6.0688 L7.2,4 C7.2,3.5581722 6.8418278,3.2 6.4,3.2 C5.9581722,3.2 5.6,3.5581722 5.6,4 L5.6,6.4 C5.6,6.6128 5.684,6.816 5.8344,6.9656 L7.4344,8.5656 C7.5841081,8.71616739 7.78767229,8.80082788 8,8.80082788 C8.21232771,8.80082788 8.4158919,8.71616739 8.5656,8.5656 C8.71577309,8.41568205 8.80016093,8.21219648 8.80016093,8 C8.80016093,7.78780352 8.71577309,7.58431795 8.5656,7.4344 L7.2,6.0688 Z"
        transform=""
      />
    </g>
  </svg>
);

SearchHistoryIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

SearchHistoryIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default SearchHistoryIcon;
