/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_caret_up.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CaretUpIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon
        fill={color}
        fillRule="nonzero"
        points="3 5 8 10 13 5"
        transform="translate(8.000000, 7.500000) rotate(-180.000000) translate(-8.000000, -7.500000) "
      />
    </g>
  </svg>
);

CaretUpIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CaretUpIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default CaretUpIcon;
