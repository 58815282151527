/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_star.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const StarIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M3.20026334,16.0001081 C3.03148514,16.0001081 2.86697367,15.9469768 2.73026334,15.848 C2.48974366,15.673512 2.3657514,15.3800893 2.40826334,15.086 L3.15126334,9.883 L0.234263342,6.966 C0.0223523883,6.75403479 -0.0532979912,6.44139801 0.0382633415,6.156 C0.129570931,5.8708318 0.372833438,5.66077655 0.668263342,5.612 L5.06626334,4.878 L7.28426334,0.443 C7.42171026,0.171547587 7.69999707,0.000323898777 8.00426334,-1.24344979e-14 C8.30882514,0.00166771541 8.58602012,0.176126095 8.71926334,0.45 L10.8422633,4.812 L15.3402633,5.613 C15.6335791,5.66511246 15.8736429,5.87589081 15.9632633,6.16 C16.0531592,6.44455453 15.9767759,6.75548872 15.7652633,6.966 L12.8482633,9.883 L13.5922633,15.086 C13.6344623,15.3817461 13.5083625,15.6763645 13.2652633,15.85 C13.0231284,16.0239598 12.7042798,16.0486349 12.4382633,15.914 L8.05626334,13.694 L3.55426334,15.917 C3.44429678,15.9717452 3.3231033,16.0001081 3.20026334,16.0001081 Z M4.71026334,13.116 L8.06726334,11.458 L8.95926334,11.91 L11.2862633,13.088 L10.7262633,9.176 L11.4342633,8.469 L12.7242633,7.179 L9.49026334,6.603 L9.04426334,5.688 L7.98426334,3.512 L6.40026334,6.683 L5.39526334,6.851 L3.29726334,7.201 L5.27226334,9.176 L5.13126334,10.166 L4.71026334,13.116 Z"
        transform=""
      />
    </g>
  </svg>
);

StarIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

StarIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default StarIcon;
