/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_print.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const PrintIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule=""
        d="M15.2,3.2 L12.8,3.2 L12.8,0.8 C12.8,0.3581722 12.4418278,0 12,0 L4,0 C3.5581722,0 3.2,0.3581722 3.2,0.8 L3.2,3.2 L0.8,3.2 C0.3581722,3.2 0,3.5581722 0,4 L0,12 C0,12.4418278 0.3581722,12.8 0.8,12.8 L3.2,12.8 L3.2,15.2 C3.2,15.6418278 3.5581722,16 4,16 L12,16 C12.4418278,16 12.8,15.6418278 12.8,15.2 L12.8,12.8 L15.2,12.8 C15.6418278,12.8 16,12.4418278 16,12 L16,4 C16,3.5581722 15.6418278,3.2 15.2,3.2 Z M11.2,7.2 C11.2,7.6418278 11.5581722,8 12,8 C12.4418278,8 12.8,7.6418278 12.8,7.2 C12.8,6.7581722 12.4418278,6.4 12,6.4 C11.5581722,6.4 11.2,6.7581722 11.2,7.2 M14.4,11.2 L12.8,11.2 L12.8,10.4 C12.8,9.9581722 12.4418278,9.6 12,9.6 L4,9.6 C3.5581722,9.6 3.2,9.9581722 3.2,10.4 L3.2,11.2 L1.6,11.2 L1.6,4.8 L14.4,4.8 L14.4,11.2 Z M4.8,14.4 L11.2,14.4 L11.2,11.2 L4.8,11.2 L4.8,14.4 Z M4.8,3.2 L11.2,3.2 L11.2,1.6 L4.8,1.6 L4.8,3.2 Z M5.6,6.4 L4,6.4 C3.5581722,6.4 3.2,6.7581722 3.2,7.2 C3.2,7.6418278 3.5581722,8 4,8 L5.6,8 C6.0418278,8 6.4,7.6418278 6.4,7.2 C6.4,6.7581722 6.0418278,6.4 5.6,6.4"
        transform=""
      />
    </g>
  </svg>
);

PrintIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

PrintIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default PrintIcon;
