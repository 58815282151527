/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_note.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const NoteIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M14.1656,3.43440085 L10.9656,0.234400851 C10.8157558,0.0841371732 10.6122084,-0.000218365473 10.4,4.24540403e-07 L2.4,4.24540403e-07 C1.9581722,4.24540403e-07 1.6,0.358173051 1.6,0.800000851 L1.6,15.2000009 C1.6,15.6418287 1.9581722,16.0000004 2.4,16.0000004 L13.6,16.0000004 C14.0418278,16.0000004 14.4000004,15.6418287 14.4000004,15.2000009 L14.4000004,4.00000042 C14.4002192,3.78779241 14.3158637,3.58424509 14.1656,3.43440085 Z M12.8,14.4000009 L3.2,14.4000009 L3.2,1.60000085 L10.0688,1.60000085 L12.8,4.33120085 L12.8,14.4000009 Z M10.4,10.4000009 L5.6,10.4000009 C5.1581722,10.4000009 4.8,10.7581731 4.8,11.2000009 C4.8,11.6418287 5.1581722,12.0000009 5.6,12.0000009 L10.4,12.0000009 C10.8418278,12.0000009 11.2,11.6418287 11.2,11.2000009 C11.2,10.7581731 10.8418278,10.4000009 10.4,10.4000009 M4.8,8.00000085 C4.8,8.44182865 5.1581722,8.80000085 5.6,8.80000085 L10.4,8.80000085 C10.8418278,8.80000085 11.2,8.44182865 11.2,8.00000085 C11.2,7.55817305 10.8418278,7.20000085 10.4,7.20000085 L5.6,7.20000085 C5.1581722,7.20000085 4.8,7.55817305 4.8,8.00000085 M5.6,5.60000085 L6.4,5.60000085 C6.8418278,5.60000085 7.2,5.24182865 7.2,4.80000085 C7.2,4.35817305 6.8418278,4.00000085 6.4,4.00000085 L5.6,4.00000085 C5.1581722,4.00000085 4.8,4.35817305 4.8,4.80000085 C4.8,5.24182865 5.1581722,5.60000085 5.6,5.60000085"
        transform=""
      />
    </g>
  </svg>
);

NoteIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

NoteIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default NoteIcon;
