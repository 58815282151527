/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_attachment.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const AttachmentIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M4.1944,16.000005 C3.08213459,16.0017169 2.01497918,15.5604034 1.2288,14.7736 C0.439640885,13.9884754 -0.00280360536,12.9203868 -1.32432281e-05,11.8072 C-1.32432281e-05,10.6856 0.4368,9.632 1.2288,8.8408 L8.8408,1.2256 C10.4800254,-0.408945476 13.1327746,-0.408945476 14.772,1.2256 C15.5610167,2.01075531 16.0031936,3.07889355 16.0000202,4.192 C16.0034615,5.30515928 15.5612435,6.37339662 14.772,7.1584 L11.3656,10.5664 C10.3899691,11.5402652 8.81003087,11.5402652 7.8344,10.5664 C7.36424558,10.099081 7.10089597,9.46289286 7.1032,8.8 C7.1032,8.132 7.3624,7.5048 7.8344,7.0336 L11.4344,3.4328 C11.6352596,3.22483473 11.9327023,3.14142992 12.2124059,3.21464227 C12.4921094,3.28785463 12.7105454,3.5062906 12.7837577,3.78599414 C12.8569701,4.06569769 12.7735653,4.36314042 12.5656,4.564 L8.9656,8.1648 C8.79665082,8.33288481 8.70213487,8.56168257 8.7032,8.8 C8.7032,9.04 8.796,9.264 8.9656,9.4344 C9.31696935,9.78234709 9.88303065,9.78234709 10.2344,9.4344 L13.6408,6.0264 C14.1284897,5.5407251 14.4018304,4.88027173 14.4,4.192 C14.4,3.4992 14.1304,2.8472 13.6408,2.3576 C12.6267394,1.34678374 10.9860606,1.34678374 9.972,2.3576 L2.36,9.972 C1.87162206,10.4579336 1.59795377,11.1190586 1.6,11.808 C1.6,12.5008 1.8704,13.152 2.36,13.6424 C3.37406064,14.6532163 5.01473936,14.6532163 6.0288,13.6424 L7.4344,12.236 C7.63525958,12.0280347 7.93270231,11.9446299 8.21240586,12.0178423 C8.4921094,12.0910546 8.71054537,12.3094906 8.78375773,12.5891941 C8.85697008,12.8688977 8.77356527,13.1663404 8.5656,13.3672 L7.16,14.7736 C6.373521,15.5599545 5.3065606,16.0011874 4.1944,16.000005"
        transform=""
      />
    </g>
  </svg>
);

AttachmentIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

AttachmentIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default AttachmentIcon;
