/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_key.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const KeyIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M4.8,15.9999992 C2.1528,15.9999992 0,13.8472 0,11.2 C0,8.5528 2.1528,6.4 4.8,6.4 C5.1736,6.4 5.5448,6.448 5.924,6.5448 L12.2344,0.2344 C12.384194,0.0840612728 12.587774,-0.000307805656 12.8,-8.39281665e-07 L15.2,-8.39281665e-07 C15.6418278,-8.39281665e-07 16,0.3581722 16,0.8 L16,5.6 C16,6.0418278 15.6418278,6.4 15.2,6.4 L13.6,6.4 L13.6,8.8 C13.6,9.2418278 13.2418278,9.6 12.8,9.6 L9.9312,9.6 L9.4552,10.076 C9.552,10.4552 9.6,10.8264 9.6,11.2 C9.6,13.8472 7.4472,15.9999992 4.8,15.9999992 M5.6,11.2 C5.6,11.6418278 5.2418278,12 4.8,12 C4.3581722,12 4,11.6418278 4,11.2 C4,10.7581722 4.3581722,10.4 4.8,10.4 C5.2418278,10.4 5.6,10.7581722 5.6,11.2 M4.8,8 C3.0352,8 1.6,9.4352 1.6,11.2 C1.6,12.9648 3.0352,14.4 4.8,14.4 C6.5648,14.4 8,12.9648 8,11.2 C8,10.8528 7.9336,10.5 7.7968,10.1208 C7.6918195,9.82915493 7.76475803,9.50311347 7.984,9.284 L9.0344,8.2344 C9.184194,8.08406127 9.38777399,7.99969219 9.6,8 L12,8 L12,5.6 C12,5.1581722 12.3581722,4.8 12.8,4.8 L14.4,4.8 L14.4,1.6 L13.1312,1.6 L6.716,8.016 C6.49639675,8.23434989 6.17090806,8.30716476 5.8792,8.2032 C5.53415512,8.07274359 5.16886195,8.00396341 4.8,8"
        transform=""
      />
    </g>
  </svg>
);

KeyIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

KeyIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default KeyIcon;
