/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_chevron_up.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ChevronUpIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M11.1999637,15.5722665 C10.9713893,15.5726445 10.7536733,15.474716 10.6023637,15.3033922 L4.20236366,8.10339223 C3.93291797,7.80051147 3.93291797,7.34387299 4.20236366,7.04099223 L10.6023637,-0.159007769 C10.7921431,-0.372509694 11.0814085,-0.468547459 11.3611964,-0.410944567 C11.6409842,-0.353341675 11.868788,-0.150849375 11.9587964,0.120255441 C12.0488047,0.391360257 11.9873431,0.689890316 11.7975637,0.903392231 L5.86956366,7.57219223 L11.7975637,14.2409922 C12.0073741,14.4762013 12.0593473,14.8127572 11.9302639,15.1003006 C11.8011804,15.3878439 11.5151517,15.5726646 11.1999637,15.5722665"
        transform="translate(8.000327, 7.572439) rotate(-270.000000) translate(-8.000327, -7.572439) "
      />
    </g>
  </svg>
);

ChevronUpIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ChevronUpIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default ChevronUpIcon;
