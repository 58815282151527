/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_location.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const LocationIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M8,0 C4.4712,0 1.6,2.8712 1.6,6.4 C1.6,12.396 7.3576,15.7552 7.6032,15.8952 C7.84891627,16.0363062 8.15108373,16.0363062 8.3968,15.8952 C8.6424,15.7552 14.4,12.396 14.4,6.4 C14.4,2.8704 11.5288,0 8,0 M7.9992,14.2504 C6.7824,13.4256 3.2,10.6368 3.2,6.4 C3.2,3.752 5.3528,1.6 8,1.6 C10.6472,1.6 12.8,3.752 12.8,6.4 C12.8,10.6208 9.2144,13.4224 7.9992,14.2504 M8,8 C7.1176,8 6.4,7.2824 6.4,6.4 C6.4,5.5176 7.1176,4.8 8,4.8 C8.8824,4.8 9.6,5.5176 9.6,6.4 C9.6,7.2824 8.8824,8 8,8 M8,3.2 C6.2352,3.2 4.8,4.6352 4.8,6.4 C4.8,8.1648 6.2352,9.6 8,9.6 C9.7648,9.6 11.2,8.1648 11.2,6.4 C11.2,4.6352 9.7648,3.2 8,3.2"
        transform=""
      />
    </g>
  </svg>
);

LocationIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

LocationIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default LocationIcon;
