/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_filter.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const FilterIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M0.8,3.2 L3.348,3.2 C3.68470713,4.15595774 4.58648351,4.79665144 5.6,4.8 C6.61351649,4.79665144 7.51529287,4.15595774 7.852,3.2 L15.2,3.2 C15.6418278,3.2 16,2.8418278 16,2.4 C16,1.9581722 15.6418278,1.6 15.2,1.6 L7.852,1.6 C7.51529287,0.644042264 6.61351649,0.0033485603 5.6,0 C4.58648351,0.0033485603 3.68470713,0.644042264 3.348,1.6 L0.8,1.6 C0.3581722,1.6 0,1.9581722 0,2.4 C0,2.8418278 0.3581722,3.2 0.8,3.2 M15.2,12.8 L7.852,12.8 C7.51529287,11.8440423 6.61351649,11.2033486 5.6,11.2 C4.58648351,11.2033486 3.68470713,11.8440423 3.348,12.8 L0.8,12.8 C0.3581722,12.8 0,13.1581722 0,13.6 C0,14.0418278 0.3581722,14.4 0.8,14.4 L3.348,14.4 C3.68470713,15.3559577 4.58648351,15.9966514 5.6,16 C6.61351649,15.9966514 7.51529287,15.3559577 7.852,14.4 L15.2,14.4 C15.6418278,14.4 16,14.0418278 16,13.6 C16,13.1581722 15.6418278,12.8 15.2,12.8 M15.2,7.2 L12.652,7.2 C12.3152929,6.24404226 11.4135165,5.60334856 10.4,5.6 C9.38648351,5.60334856 8.48470713,6.24404226 8.148,7.2 L0.8,7.2 C0.3581722,7.2 0,7.5581722 0,8 C0,8.4418278 0.3581722,8.8 0.8,8.8 L8.148,8.8 C8.48470713,9.75595774 9.38648351,10.3966514 10.4,10.4 C11.4135165,10.3966514 12.3152929,9.75595774 12.652,8.8 L15.2,8.8 C15.6418278,8.8 16,8.4418278 16,8 C16,7.5581722 15.6418278,7.2 15.2,7.2 M5.6,1.6 C6.0418278,1.6 6.4,1.9581722 6.4,2.4 C6.4,2.8418278 6.0418278,3.2 5.6,3.2 C5.1581722,3.2 4.8,2.8418278 4.8,2.4 C4.8,1.9581722 5.1581722,1.6 5.6,1.6 M5.6,14.4 C5.1581722,14.4 4.8,14.0418278 4.8,13.6 C4.8,13.1581722 5.1581722,12.8 5.6,12.8 C6.0418278,12.8 6.4,13.1581722 6.4,13.6 C6.4,14.0418278 6.0418278,14.4 5.6,14.4 M10.4,8.8 C9.9581722,8.8 9.6,8.4418278 9.6,8 C9.6,7.5581722 9.9581722,7.2 10.4,7.2 C10.8418278,7.2 11.2,7.5581722 11.2,8 C11.2,8.4418278 10.8418278,8.8 10.4,8.8"
        transform=""
      />
    </g>
  </svg>
);

FilterIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

FilterIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default FilterIcon;
