/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_plus.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const PlusIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15,7 L9,7 L9,1 C9,0.44771525 8.55228475,0 8,0 C7.44771525,0 7,0.44771525 7,1 L7,7 L1,7 C0.44771525,7 0,7.44771525 0,8 C0,8.55228475 0.44771525,9 1,9 L7,9 L7,15 C7,15.5522847 7.44771525,16 8,16 C8.55228475,16 9,15.5522847 9,15 L9,9 L15,9 C15.5522847,9 16,8.55228475 16,8 C16,7.44771525 15.5522847,7 15,7"
        transform=""
      />
    </g>
  </svg>
);

PlusIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

PlusIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default PlusIcon;
