import { useLocation, useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import queryString from "query-string";

const useCurrentTab = (paramName = "tab") => {
  // v6
  const [searchParams] = useSearchParams?.() || [];

  // v5
  const location = useLocation?.();
  const queryParams = useMemo(
    () => (location ? queryString.parse(location.search) : {}),
    [location]
  );

  return searchParams ? searchParams.get(paramName) : queryParams[paramName];
};

export default useCurrentTab;
