/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_circle_disabled.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CircleDisabledIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M1.6,8 C1.6,6.5232 2.108,5.1656 2.9504,4.0824 L11.9176,13.0496 C10.7989838,13.9242349 9.41996064,14.3995853 8,14.4 C4.4712,14.4 1.6,11.5288 1.6,8 M13.0496,11.9176 L4.0824,2.9504 C5.2010162,2.07576511 6.58003936,1.60041468 8,1.6 C11.5288,1.6 14.4,4.4712 14.4,8 C14.3997083,9.41998126 13.9243445,10.7990433 13.0496,11.9176 M0,8 C0,12.4112 3.5888,16 8,16 C12.4112,16 16,12.4112 16,8 C16,3.5888 12.4112,0 8,0 C3.5888,0 0,3.5888 0,8"
        transform=""
      />
    </g>
  </svg>
);

CircleDisabledIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CircleDisabledIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default CircleDisabledIcon;
