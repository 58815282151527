/* eslint-disable no-magic-numbers, import/prefer-default-export */
// The variables here correspond to those in variables.less and colors.less

const ColorPalette = {
  // This is for setting colors in SVGs, and should mirror the colors in colors.less
  // green
  greenLight: "#00f4b6",
  greenFaint: "#ccfcf0",
  greenNormal: "#1eb980",
  greenDark: "#00804d",
  greenOff: "#005f56",
  // red
  redFaint: "#ffe5e3",
  redLight: "#ffd5ce",
  redNormal: "#ff7d77",
  redDark: "#ff5b50",
  redOff: "#a5473d",
  // violet
  violetLight: "#c5cae9",
  violetNormal: "#7986cb",
  violetDark: "#3f51b5",
  // yellow
  yellowLight: "#ffa900",
  // white
  fullWhite: "rgba(255, 255, 255, 1)",
  darkWhite: "rgba(255, 255, 255, .8)",
  lightWhite: "rgba(255, 255, 255, .5)",
  faintWhite: "rgba(255, 255, 255, .3)",
  minWhite: "rgba(255, 255, 255, .15)",
  white10: "rgba(255, 255, 255, 0.1)", // Active states
  white05: "rgba(255, 255, 255, 0.05)",

  // black
  fullBlack: "rgba(0, 0, 0, 1)",
  darkBlack: "rgba(0, 0, 0, .8)",
  lightBlack: "rgba(0, 0, 0, .5)",
  faintBlack: "rgba(0, 0, 0, .3)",
  minBlack: "rgba(0, 0, 0, .15)",
  black10: "rgba(0, 0, 0, 0.1)",
  black05: "rgba(0, 0, 0, 0.05)", // darken the background to create layers
  // solid greyscale
  lightGray: "#6b6b6b",
  // elevation
  elevation0: "#1b1b1b",
  elevation1: "#242424",
  elevation2: "#2b2b2b",
  elevation3: "#3b3b3b",
  elevation4: "#4b4b4b",
  elevation5: "#5b5b5b",
  // Dark background colors for the page, panels, and other layered components
};

// dark table colors
const TableColors = {
  border: "#4b4b4b",
  header: "#4b4b4b",
  headerText: "rgba(255, 255, 255, .5)",
  headerActive: "#6b6b6b",
  headerHover: "#555555",
  row: "#3b3b3b",
  rowText: "rgba(255, 255, 255, .5)",
  rowActive: "#555555",
  rowActiveText: "rgba(255, 255, 255, 1)",
  rowHover: "#444444",
  rowSelected: "#1dca7f",
  rowSelectedText: "rgba(255, 255, 255, 1)",
};

// Used in Live Tickets sections in Activity Tab. Most are one-off colors.
const LiveTicketChartColors = [
  ColorPalette.violetNormal,
  "#c885c5",
  "#fb8ba6",
  "#ffa581",
  "#f9cb6f",
  ColorPalette.lightWhite,
];

// Z-INDEXES
// ---------
// relevant context from 3rd party libraries:
// .selectize-dropdown: 10  <- low but it is okay because selectize is nested in the DOM
// #nprogress .bar/.spinner: 1031
// .qtip: ~ 15000  <- it is set inline by the library to approximate values
const zIndexVisible = 1; // when you just need an element to have ANY value
const zIndexExtraVisible = zIndexVisible + 1; // when it needs to be above that

const zIndexSiteHeader = 50;
const zIndexAboveSiteHeader = zIndexSiteHeader + 1;
const zIndexModalShadow = zIndexSiteHeader + 10;
const zIndexModal = zIndexModalShadow + 1;
const zIndexModalSelect = zIndexModal + 1;
const zIndexModalLoadingSpinner = zIndexModalSelect + 1; // display loading spinner over all other content in modal
const zIndexBelowSiteHeader = zIndexSiteHeader - 1; // when scrolled over the header, it should still be below
// these values are used a lot in buyside deal header and DD edit teaser heightlight

const zIndexSideNav = zIndexSiteHeader - 10; // originally 999 in materialize
const zIndexSideNavOverlay = zIndexSideNav - 2; // relative z-index is the same

const zIndexCursor = 100; // things under the mouse like contextmenus or dragged elements
// > @z-index-site-header because it shouldn't be covered if you drag into that area
// these values are used mostly in data room dragging

const zIndexAriaModal = 1050; // z-index of AriaModel from react-aria-modal
const zIndexAriaModalSelect = zIndexAriaModal + 1;

const Z_INDEXES = {
  zIndexVisible,
  zIndexExtraVisible,
  zIndexAboveSiteHeader,
  zIndexModalShadow,
  zIndexModal,
  zIndexModalSelect,
  zIndexModalLoadingSpinner,
  zIndexBelowSiteHeader,
  zIndexSideNav,
  zIndexSideNavOverlay,
  zIndexCursor,
  zIndexAriaModal,
  zIndexAriaModalSelect,
};

// Breakpoints for responsive sizing
const mobileScreen = 420;
const smallScreen = 600;
const mediumScreen = 992;
const largeScreen = 1200;
const extraLargeScreen = 1440;

// Media screen settings
const smallAndUp = `only screen and (min-width : ${mobileScreen + 1}px)`;
const mediumAndUp = `only screen and (min-width : ${smallScreen + 1}px)`;
const largeAndUp = `only screen and (min-width : ${mediumScreen + 1}px)`;
const extraLargeAndUp = `only screen and (min-width : ${largeScreen + 1}px)`;

const mobileAndDown = `only screen and (max-width : ${mobileScreen}px)`;
const smallAndDown = `only screen and (max-width : ${smallScreen}px)`;
const mediumAndDown = `only screen and (max-width : ${mediumScreen}px)`;
const largeAndDown = `only screen and (max-width : ${largeScreen}px)`;
const extraLargeAndDown = `only screen and (max-width : ${extraLargeScreen}px)`;

const MEDIA_QUERIES = {
  smallAndUp,
  mediumAndUp,
  largeAndUp,
  extraLargeAndUp,
  mobileAndDown,
  smallAndDown,
  mediumAndDown,
  largeAndDown,
  extraLargeAndDown,
};

export { ColorPalette, LiveTicketChartColors, TableColors, Z_INDEXES, MEDIA_QUERIES };
