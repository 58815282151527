/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_caret_down.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CaretDownIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon fill={color} fillRule="nonzero" points="3 5 8 10 13 5" transform="" />
    </g>
  </svg>
);

CaretDownIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CaretDownIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default CaretDownIcon;
