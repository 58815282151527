import PropTypes from "prop-types";
import styled from "styled-components";

/**
 * Yuka v2.0
 * List styles
 *
 * @param {object} props
 * @returns {React.Element}
 */
const List = styled.div.attrs({ className: "zx-list" })`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
`;

List.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

List.defaultProps = {
  className: "",
};

/* @component */
export default List;
