/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_send.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const SendIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.6752,0.1568 C15.4331429,-0.021675187 15.1114967,-0.0494832165 14.8424,0.0848 L0.4424,7.2848 C0.181144224,7.41525841 0.0117267556,7.67770313 0.000401286519,7.96950051 C-0.0109241826,8.26129789 0.137641844,8.53608443 0.388,8.6864 L5.6,11.8136 L5.6,15.2 C5.6003183,15.4771115 5.74388008,15.7343637 5.97955398,15.880133 C6.21522787,16.0259022 6.50951454,16.0394685 6.7576,15.916 L9.856,14.3656 L12.388,15.8856 C12.6155132,16.0204064 12.8945951,16.0353466 13.1352,15.9256 C13.3759574,15.8149593 13.5459669,15.5918781 13.5888,15.3304 L15.9888,0.9304 C16.0386401,0.633785609 15.9173838,0.334356774 15.6752,0.156 L15.6752,0.1568 Z M12.2032,13.9088 L7.5288,11.1048 L13.9328,3.536 L12.204,13.9096 L12.2032,13.9088 Z M7.2,13.9056 L7.2,12.7736 L8.2296,13.3912 L7.2,13.9048 L7.2,13.9056 Z M12.072,3.2584 L6.1392,10.2704 L2.4608,8.064 L12.072,3.2576 L12.072,3.2584 Z"
        transform=""
      />
    </g>
  </svg>
);

SendIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

SendIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default SendIcon;
