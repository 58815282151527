/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_lock.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const LockIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule=""
        d="M13.6,6.4 L12.8,6.4 L12.8,4.8 C12.8,2.1528 10.6472,0 8,0 C5.3528,0 3.2,2.1528 3.2,4.8 L3.2,6.4 L2.4,6.4 C1.9581722,6.4 1.6,6.7581722 1.6,7.2 L1.6,15.2 C1.6,15.6418278 1.9581722,16 2.4,16 L13.6,16 C14.0418278,16 14.4,15.6418278 14.4,15.2 L14.4,7.2 C14.4,6.7581722 14.0418278,6.4 13.6,6.4 Z M3.2,14.4 L12.8,14.4 L12.8,8 L3.2,8 L3.2,14.4 Z M4.8,4.8 C4.8,3.0352 6.2352,1.6 8,1.6 C9.7648,1.6 11.2,3.0352 11.2,4.8 L11.2,6.4 L4.8,6.4 L4.8,4.8 Z M8,12.8 C8.4418278,12.8 8.8,12.4418278 8.8,12 L8.8,10.4 C8.8,9.9581722 8.4418278,9.6 8,9.6 C7.5581722,9.6 7.2,9.9581722 7.2,10.4 L7.2,12 C7.2,12.4418278 7.5581722,12.8 8,12.8"
        transform=""
      />
    </g>
  </svg>
);

LockIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

LockIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default LockIcon;
