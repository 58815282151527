import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import DefinitionListHeader from "./DefinitionListHeader";
import { FontColors, body1 } from "../Typography";

const StyledWrapper = styled.div`
  ${body1}
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0 12px;

  & + ${DefinitionListHeader} {
    // When multiple lists consecutively, give a bit extra space
    // Because it's vertical margins, they collapse, we want 12px total here
    margin-top: 12px;
  }
`;

const StyledLabel = styled.span`
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${FontColors.light}
`;

const StyledValue = styled.span`
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/**
 * Yuka v2.0
 * This is a component used to display a key-value pair, typically in a list
 *
 * @param {object} props
 *
 * @returns {Element}
 */
const Definition = props => (
  <StyledWrapper className={props.className}>
    <StyledLabel>{props.label}</StyledLabel>
    <StyledValue>{props.value || <span>&mdash;</span>}</StyledValue>
  </StyledWrapper>
);

Definition.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.node,
  className: PropTypes.string,
};

Definition.defaultProps = {
  value: null,
  className: "",
};

const StyledDefinition = styled(Definition)``;

StyledDefinition.displayName = "Definition";

/* @component */
export default StyledDefinition;
