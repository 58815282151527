/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_risk.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const RiskIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M8.7152,0.4072 C8.444,-0.1352 7.556,-0.1352 7.284,0.4072 L0.084,14.8072 C-0.0404354394,15.0552125 -0.0273232714,15.3500196 0.118639013,15.5860056 C0.264601297,15.8219915 0.522522035,15.9653795 0.8,15.964803 L15.2,15.964803 C15.4775198,15.9655533 15.7355222,15.8221413 15.8813887,15.5860464 C16.0272552,15.3499515 16.0400576,15.0550475 15.9152,14.8072 L8.7152,0.4072 L8.7152,0.4072 Z M2.0944,14.3648 L8,2.5536 L13.9056,14.3648 L2.0944,14.3648 Z M7.2,8.7648 C7.2,9.2066278 7.5581722,9.5648 8,9.5648 C8.4418278,9.5648 8.8,9.2066278 8.8,8.7648 L8.8,7.1648 C8.8,6.7229722 8.4418278,6.3648 8,6.3648 C7.5581722,6.3648 7.2,6.7229722 7.2,7.1648 L7.2,8.7648 Z M7.11999963,11.9648 C7.12044183,12.4508106 7.51478942,12.8444418 8.0008,12.8440004 C8.48681058,12.8435582 8.88044183,12.4492106 8.88000037,11.9632 C8.87971419,11.6488063 8.71172303,11.358447 8.43930718,11.2014976 C8.16689133,11.0445483 7.83143722,11.0448533 7.55930718,11.2022976 C7.28717714,11.359742 7.11971419,11.6504063 7.11999963,11.9648 L7.11999963,11.9648 Z"
        transform=""
      />
    </g>
  </svg>
);

RiskIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

RiskIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default RiskIcon;
