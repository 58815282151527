import React from "react";
import PropTypes from "prop-types";

import { TabList } from "../Panel";

import BasicCard from "./BasicCard";
import TitleCard from "./TitleCard";

/**
 * Yuka v2.0
 * A design component that renders a card at a specified elevation level with tabs within it.
 * Optionally includes a title
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const TabCard = props => {
  const WrappingCard = props.title ? TitleCard : BasicCard;
  const [currentTab, setCurrentTab] = React.useState(props.defaultTab || props.tabs[0]);

  const { onTabSelect: onTabSelectProp } = props;

  const onTabSelect = React.useCallback(
    tab => {
      setCurrentTab(tab);
      onTabSelectProp(tab);
    },
    [onTabSelectProp]
  );

  return (
    <WrappingCard title={props.title} elevation={props.elevation} action={props.action}>
      <TabList currentTab={currentTab} tabNames={props.tabs} handleChangeTab={onTabSelect} />
      {props.children}
    </WrappingCard>
  );
};

TabCard.propTypes = {
  title: PropTypes.node,
  defaultTab: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onTabSelect: PropTypes.func.isRequired,
  action: PropTypes.node,
  ...BasicCard.propTypes,
};

TabCard.defaultProps = {
  action: null,
  title: "",
  defaultTab: "",
};

export default TabCard;
