/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_image.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ImageIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.2,0 L0.8,0 C0.3581722,0 0,0.3581722 0,0.8 L0,15.2 C0,15.6418278 0.3581722,16 0.8,16 L15.2,16 C15.6418278,16 16,15.6418278 16,15.2 L16,0.8 C16,0.3581722 15.6418278,0 15.2,0 Z M6.4,4.8 C6.8408,4.8 7.2,5.1592 7.2,5.6 C7.2,6.0408 6.8408,6.4 6.4,6.4 C5.9592,6.4 5.6,6.0408 5.6,5.6 C5.6,5.1592 5.9592,4.8 6.4,4.8 M6.4,8 C7.7232,8 8.8,6.9232 8.8,5.6 C8.8,4.2768 7.7232,3.2 6.4,3.2 C5.0768,3.2 4,4.2768 4,5.6 C4,6.9232 5.0768,8 6.4,8 M1.6,14.4 L1.6,12.4528 L4.7776,10.5464 L6.756,11.8656 C7.07334119,12.0772059 7.49593652,12.0353482 7.7656,11.7656 L11.2592,8.272 L14.4,10.784 L14.4,14.4 L1.6,14.4 Z M14.4,1.6 L14.4,8.7352 L11.7,6.5752 C11.3816123,6.32050462 10.922616,6.34600442 10.6344,6.6344 L7.0984,10.1704 L5.244,8.9344 C4.98654593,8.76274975 4.65318979,8.75496105 4.388,8.9144 L1.6,10.5872 L1.6,1.6 L14.4,1.6 Z"
        transform=""
      />
    </g>
  </svg>
);

ImageIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ImageIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default ImageIcon;
