/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_list.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ListIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule=""
        d="M15.2,0 L0.8,0 C0.3581722,0 0,0.3581722 0,0.8 L0,15.2 C0,15.6418278 0.3581722,16 0.8,16 L15.2,16 C15.6418278,16 16,15.6418278 16,15.2 L16,0.8 C16,0.3581722 15.6418278,0 15.2,0 Z M1.6,14.4 L14.4,14.4 L14.4,1.6 L1.6,1.6 L1.6,14.4 Z M7.2,5.6 L12,5.6 C12.4418278,5.6 12.8,5.2418278 12.8,4.8 C12.8,4.3581722 12.4418278,4 12,4 L7.2,4 C6.7581722,4 6.4,4.3581722 6.4,4.8 C6.4,5.2418278 6.7581722,5.6 7.2,5.6 M4,5.6 C4.4418278,5.6 4.8,5.2418278 4.8,4.8 C4.8,4.3581722 4.4418278,4 4,4 C3.5581722,4 3.2,4.3581722 3.2,4.8 C3.2,5.2418278 3.5581722,5.6 4,5.6 M7.2,8.8 L12,8.8 C12.4418278,8.8 12.8,8.4418278 12.8,8 C12.8,7.5581722 12.4418278,7.2 12,7.2 L7.2,7.2 C6.7581722,7.2 6.4,7.5581722 6.4,8 C6.4,8.4418278 6.7581722,8.8 7.2,8.8 M4,8.8 C4.4418278,8.8 4.8,8.4418278 4.8,8 C4.8,7.5581722 4.4418278,7.2 4,7.2 C3.5581722,7.2 3.2,7.5581722 3.2,8 C3.2,8.4418278 3.5581722,8.8 4,8.8 M7.2,12 L12,12 C12.4418278,12 12.8,11.6418278 12.8,11.2 C12.8,10.7581722 12.4418278,10.4 12,10.4 L7.2,10.4 C6.7581722,10.4 6.4,10.7581722 6.4,11.2 C6.4,11.6418278 6.7581722,12 7.2,12 M4,12 C4.4418278,12 4.8,11.6418278 4.8,11.2 C4.8,10.7581722 4.4418278,10.4 4,10.4 C3.5581722,10.4 3.2,10.7581722 3.2,11.2 C3.2,11.6418278 3.5581722,12 4,12"
        transform=""
      />
    </g>
  </svg>
);

ListIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ListIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default ListIcon;
