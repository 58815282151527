import PropTypes from "prop-types";
import { Table as TangeloTable } from "tangelo";
import styled from "styled-components";

import { size12, size14 } from "../Typography";
import { ColorPalette, TableColors } from "../StylingConstants";

const headerColor = TableColors.header;
const headerTextColor = TableColors.headerText;
const headerHoverColor = TableColors.headerHover;
const headerActiveColor = TableColors.headerActive;
const rowColor = TableColors.row;
const rowTextColor = TableColors.rowText;
const rowHoverColor = TableColors.rowHover;
const rowActiveColor = TableColors.rowActive;
const rowActiveTextColor = TableColors.rowActiveText;
const borderColor = TableColors.border;

/**
 * Yuka v2.0
 *
 * The standard base Table configuration
 *
 * @param {object} props
 * @returns {Element}
 */
const Table = styled(TangeloTable).attrs({ rowHeight: 40, headerHeight: 32 })`
  border: none;

  .Tangelo__TableRow {
    border-bottom: 1px solid ${borderColor};
  }

  .Tangelo__TableCell {
    border-right: none;
  }

  .Tangelo__TableRow--header {
    background-color: ${headerColor};
    color: ${headerTextColor};
    font-size: ${size12};

    .Tangelo__TableCell--highlightable:hover,
    &:hover:not(.Tangelo__TableRow--highlight-disabled) {
      background-color: ${headerHoverColor};
    }

    .Tangelo__TableCell--highlightable:active,
    &:active:not(.Tangelo__TableRow--highlight-disabled) {
      background-color: ${headerActiveColor};
    }
  }

  .Tangelo__HeaderSortArrow {
    rect {
      fill: ${ColorPalette.darkWhite};
      fill-opacity: 1;
    }
    path {
      fill: ${ColorPalette.lightBlack};
      fill-opacity: 1;
    }
  }

  .Tangelo__TableBody__ScrollableContent__Loader {
    background-color: inherit;
  }

  .LoaderRect {
    background-color: ${ColorPalette.faintWhite};
  }

  .Tangelo__EmptyTablePlaceholder {
    background-color: ${rowColor};
    color: ${rowTextColor};
  }

  .Tangelo__TableRow--body {
    background-color: ${rowColor};
    color: ${rowTextColor};
    font-size: ${size14};

    &:hover:not(.Tangelo__TableRow--highlight-disabled) {
      background-color: ${rowHoverColor};
    }

    &:active:not(.Tangelo__TableRow--highlight-disabled) {
      background-color: ${rowActiveColor};
    }
  }

  .Tangelo__TableCell--highlightable:hover {
    background-color: ${rowHoverColor};
  }

  .Tangelo__TableCell--highlightable:active {
    background-color: ${rowActiveColor};
  }

  .table-row--active {
    background-color: ${rowActiveColor};
    color: ${rowActiveTextColor};
  }

  ${props =>
    props.borderless
      ? `
    &.Tangelo__Table,
    .Tangelo__TableCell,
    .Tangelo__TableRow {
      border: none;
    }
  `
      : ""}

  ${props =>
    props.darkStyles
      ? `
    .Tangelo__TableRow {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }
    .Tangelo__TableRow--header {
      background-color: rgba(255, 255, 255, 0.1);
    }
    .Tangelo__TableRow--body {
      background-color: rgba(255, 255, 255, 0);
      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
      &:active {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    .Tangelo__EmptyTablePlaceholder {
      background-color: rgba(255, 255, 255, 0);
    }
  `
      : ""}
`;

// Technically `propTypes` should be all `TangeloTable` props,
// but eslint is doesn't handle dynamic props well.
Table.propTypes = {
  borderless: PropTypes.bool,
  className: PropTypes.string,
  darkStyles: PropTypes.bool,
};

Table.defaultProps = {
  borderless: false,
  className: "",
  darkStyles: false,
};

/* @component */
export default Table;
