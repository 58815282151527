/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_navigation.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const NavigationIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M8,0 C3.5888,0 0,3.5896 0,8 C0,12.4112 3.5888,16 8,16 C12.4112,16 16,12.4112 16,8 C16,3.5896 12.4112,0 8,0 M8.8,14.344 L8.8,13.6 C8.8,13.1581722 8.4418278,12.8 8,12.8 C7.5581722,12.8 7.2,13.1581722 7.2,13.6 L7.2,14.344 C4.30445633,13.9751926 2.02442519,11.6954904 1.6552,8.8 L2.4,8.8 C2.8418278,8.8 3.2,8.4418278 3.2,8 C3.2,7.5581722 2.8418278,7.2 2.4,7.2 L1.6552,7.2 C2.02442519,4.30450958 4.30445633,2.0248074 7.2,1.656 L7.2,2.4 C7.2,2.8418278 7.5581722,3.2 8,3.2 C8.4418278,3.2 8.8,2.8418278 8.8,2.4 L8.8,1.656 C11.6955437,2.0248074 13.9755748,4.30450958 14.3448,7.2 L13.6,7.2 C13.1581722,7.2 12.8,7.5581722 12.8,8 C12.8,8.4418278 13.1581722,8.8 13.6,8.8 L14.3448,8.8 C13.9755748,11.6954904 11.6955437,13.9751926 8.8,14.344 M8.5656,8.5656 C8.3592,8.764 7.4816,9.1488 6.472,9.528 C6.8504,8.5176 7.2368,7.64 7.4344,7.4352 C7.64,7.2368 8.5176,6.8504 9.528,6.472 C9.1488,7.4816 8.764,8.3592 8.5656,8.5656 M10.5832,4.4104 C9.7576,4.676 7.0104,5.596 6.3032,6.3032 C5.596,7.0104 4.676,9.7576 4.4104,10.5832 C4.31810266,10.8686962 4.39383734,11.1818359 4.6064,11.3936 C4.8180059,11.605857 5.13074255,11.681569 5.416,11.5896 C6.2424,11.324 8.9896,10.404 9.6968,9.6968 C10.404,8.9896 11.324,6.2424 11.5896,5.416 C11.6809547,5.1305816 11.6051287,4.81804166 11.3931373,4.60621871 C11.1811458,4.39439576 10.8685457,4.31881835 10.5832,4.4104"
        transform=""
      />
    </g>
  </svg>
);

NavigationIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

NavigationIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default NavigationIcon;
