/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_lit_up_message.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const LitUpMessageIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M12.8,8 C13.2418278,8 13.6,8.3581722 13.6,8.8 L13.6,12.7112 C13.6,13.1530278 13.2418278,13.5112 12.8,13.5112 L6.1088,13.5112 L3.8544,15.7656 C3.62589033,15.9951502 3.2814272,16.0640805 2.98218055,15.9401395 C2.68293389,15.8161984 2.48808453,15.5238973 2.4888,15.2 L2.4888,13.5112 L0.8,13.5112 C0.3581722,13.5112 0,13.1530278 0,12.7112 L0,3.2 C0,2.7581722 0.3581722,2.4 0.8,2.4 L7.2,2.4 C7.6418278,2.4 8,2.7581722 8,3.2 C8,3.6418278 7.6418278,4 7.2,4 L1.6,4 L1.6,11.9112 L3.2888,11.9112 C3.7306278,11.9112 4.0888,12.2693722 4.0888,12.7112 L4.0888,13.2688 L5.212,12.1456 C5.36199419,11.9955605 5.56544415,11.9112453 5.7776,11.9112 L12,11.9112 L12,8.8 C12,8.3581722 12.3581722,8 12.8,8 Z M12.8,4.00000196 C12.4761027,4.00071547 12.1838016,3.80586611 12.0598605,3.50661945 C11.9359195,3.2073728 12.0048498,2.86290967 12.2344,2.6344 L13.8344,1.0344 C14.1467723,0.722027745 14.6532277,0.722027745 14.9656,1.0344 C15.2779723,1.34677225 15.2779723,1.85322775 14.9656,2.1656 L13.3656,3.7656 C13.2157558,3.91586368 13.0122084,4.00021922 12.8,4.00000196 Z M15.2,4.8 C15.6418278,4.8 16,5.1581722 16,5.6 C16,6.0418278 15.6418278,6.4 15.2,6.4 L13.6,6.4 C13.1581722,6.4 12.8,6.0418278 12.8,5.6 C12.8,5.1581722 13.1581722,4.8 13.6,4.8 L15.2,4.8 Z M10.4,3.2 C9.9581722,3.2 9.6,2.8418278 9.6,2.4 L9.6,0.8 C9.6,0.3581722 9.9581722,-1.77635684e-15 10.4,-1.77635684e-15 C10.8418278,-1.77635684e-15 11.2,0.3581722 11.2,0.8 L11.2,2.4 C11.2,2.8418278 10.8418278,3.2 10.4,3.2 Z M3.2,6.4 C3.2,5.9581722 3.5581722,5.6 4,5.6 L9.6,5.6 C10.0418278,5.6 10.4,5.9581722 10.4,6.4 C10.4,6.8418278 10.0418278,7.2 9.6,7.2 L4,7.2 C3.5581722,7.2 3.2,6.8418278 3.2,6.4 Z M9.6,8.8 C10.0418278,8.8 10.4,9.1581722 10.4,9.6 C10.4,10.0418278 10.0418278,10.4 9.6,10.4 L4,10.4 C3.5581722,10.4 3.2,10.0418278 3.2,9.6 C3.2,9.1581722 3.5581722,8.8 4,8.8 L9.6,8.8 Z"
        transform=""
      />
    </g>
  </svg>
);

LitUpMessageIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

LitUpMessageIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default LitUpMessageIcon;
