/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_upload_arrow.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const UploadArrowIcon = ({ className, color, size }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M7.2001 11.2C7.2001 11.6418 7.55827 12 8.0001 12C8.44193 12 8.8001 11.6418 8.8001 11.2L8.8001 3.53138L12.2344 6.9657C12.5468 7.27812 13.0534 7.27812 13.3658 6.9657C13.6782 6.65328 13.6782 6.14675 13.3658 5.83433L8.56579 1.03433C8.25337 0.721906 7.74683 0.721906 7.43442 1.03433L2.63441 5.83433C2.32199 6.14674 2.32199 6.65328 2.63441 6.9657C2.94683 7.27812 3.45337 7.27812 3.76579 6.9657L7.2001 3.53138L7.2001 11.2ZM2.4001 13.6C1.95827 13.6 1.6001 13.9582 1.6001 14.4C1.6001 14.8418 1.95827 15.2 2.4001 15.2H13.6001C14.0419 15.2 14.4001 14.8418 14.4001 14.4C14.4001 13.9582 14.0419 13.6 13.6001 13.6H2.4001Z"
        transform=""
      />
    </g>
  </svg>
);

UploadArrowIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

UploadArrowIcon.defaultProps = {
  className: '',
  color: "#ffffff",
  size: 16,
};

export default UploadArrowIcon;
