import PropTypes from "prop-types";
import React from "react";

/**
 * Yuka v1.0
 * The main body of the Panel typically used as a direct child of Panels. There should only
 * be 1 body per panel because this specifies exact scrolling sizing
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const PanelBody = ({ children, className }) => (
  <div className={`panel__body ${className}`}>{children}</div>
);

PanelBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

PanelBody.defaultProps = {
  className: "",
};

export default PanelBody;
