import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { FontColors } from "../Typography";

const base = css`
  ${props => {
    if (props.$accent) {
      return css`
        &&& {
          ${FontColors.violet}
        }
      `;
    } else if (props.$active) {
      return FontColors.brightest;
    }
    return FontColors.bright;
  }}
  background-color: transparent;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    ${FontColors.brightest}
    text-decoration: underline;
  }
`;

const StyledLink = styled(Link)`
  ${base}
`;
const StyledA = styled.a`
  ${base}
`;
const StyledButton = styled.button`
  ${base}
  background: none;
  border: none;
  font-size: inherit;
  outline: inherit;
  padding: 0;
  text-transform: inherit;
`;

/**
 * Yuka v1.0
 * Wrapper component for buttons.
 *
 * @param {object} props
 *
 * @returns {Element}
 */
const HyperLink = props => {
  const rel = props.newTab ? "noreferrer noopener" : null;
  const target = props.newTab ? "_blank" : null;
  const className = `link ${props.className}`;

  const passedProps = {
    className,
    onClick: props.onClick,
    $accent: props.accent,
    $active: props.active,
  };

  const linkProps = { rel, target, download: props.download };

  if (props.to) {
    return (
      <StyledLink {...passedProps} {...linkProps} to={props.to}>
        {props.children}
      </StyledLink>
    );
  }
  if (props.href) {
    return (
      <StyledA {...passedProps} {...linkProps} href={props.href}>
        {props.children}
      </StyledA>
    );
  }
  return (
    <StyledButton {...passedProps} type="button" role="link">
      {props.children}
    </StyledButton>
  );
};

HyperLink.propTypes = {
  accent: PropTypes.bool, // applies accent style <a>
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  download: PropTypes.bool,
  href: PropTypes.string, // renders a <a>
  newTab: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // renders a react-router Link
};

HyperLink.defaultProps = {
  accent: false,
  active: false,
  className: "",
  download: false,
  href: null,
  newTab: false,
  onClick: _.noop,
  to: null,
};

export default HyperLink;
