/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_report.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ReportIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M13.6,14.4000017 L2.4,14.4000017 L2.4,12.2904017 L5.4312,8.65120167 L6.484,10.7576017 C6.60534518,10.9986524 6.83973612,11.1625589 7.10779104,11.1938105 C7.37584597,11.2250621 7.64165653,11.1194724 7.8152,10.9128017 L10.4,7.81040167 L10.4,8.80000167 C10.4,9.24182947 10.7581722,9.60000167 11.2,9.60000167 C11.6418278,9.60000167 12,9.24182947 12,8.80000167 L12,5.60000167 C12,5.58720167 11.9936,5.57760167 11.9928,5.56560167 C11.9904,5.50800167 11.9744,5.45360167 11.9592,5.39840167 C11.9472,5.35120167 11.94,5.30560167 11.92,5.26320167 C11.9,5.21920167 11.8672,5.18320167 11.8392,5.14240167 C11.8064,5.09680167 11.7768,5.05120167 11.7352,5.01440167 C11.7256,5.00560167 11.7216,4.99360167 11.7128,4.98480167 C11.6832,4.96080167 11.648,4.95280167 11.6168,4.93360167 C11.5688,4.90320167 11.5232,4.87360167 11.4696,4.85440167 C11.4168,4.83520167 11.3632,4.82960167 11.3088,4.82160167 C11.2712,4.81680167 11.2384,4.80000167 11.2,4.80000167 L8,4.80000167 C7.5581722,4.80000167 7.2,5.15817387 7.2,5.60000167 C7.2,6.04182947 7.5581722,6.40000167 8,6.40000167 L9.4912,6.40000167 L7.3688,8.94880167 L6.316,6.84240167 C6.19465482,6.60135095 5.96026388,6.43744448 5.69220896,6.40619288 C5.42415403,6.37494129 5.15834347,6.48053099 4.9848,6.68720167 L2.4,9.78960167 L2.4,1.60000167 L10.8688,1.60000167 L13.6,4.33120167 L13.6,14.4000017 Z M14.9656,3.43440167 L11.7656,0.234401674 C11.615806,0.0840629468 11.412226,-0.000306131655 11.2,8.34718994e-07 L1.6,8.34718994e-07 C1.1581722,8.34718994e-07 0.8,0.358173874 0.8,0.800001674 L0.8,15.2000017 C0.8,15.6418295 1.1581722,16.0000008 1.6,16.0000008 L14.4,16.0000008 C14.8418278,16.0000008 15.2000008,15.6418295 15.2000008,15.2000017 L15.2000008,4.00000083 C15.2003078,3.78777566 15.1159387,3.58419567 14.9656,3.43440167 Z"
        transform=""
      />
    </g>
  </svg>
);

ReportIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ReportIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default ReportIcon;
