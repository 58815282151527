import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Table from "../Table/Table";

const SIZE_TO_RADIUS = {
  "20px": "2px",
  "32px": "4px",
  "48px": "8px",
  "64px": "8px",
  "96px": "8px",
};

const SIZE_TO_SPACING = {
  "20px": "8px",
  "32px": "12px",
  "48px": "16px",
  "64px": "16px",
  "96px": "24px",
};

const StyledThumbnail = styled.img`
  vertical-align: top, ${Table} & {
    vertical-align: middle;
  }
  ${props => `
    height: ${props.size};
    width: ${props.size};
    border-radius: ${SIZE_TO_RADIUS[props.size]};
    margin-right: ${props.trailingMargin ? SIZE_TO_SPACING[props.size] : 0};
  `}
`;

/**
 * Yuka v2.0
 * This returns a rounded square with a company logo inside.
 *
 * @param {object} props
 *
 * @returns {React.Component}
 */
const CompanyThumbnail = props => (
  <StyledThumbnail
    alt={props.alt}
    size={props.size}
    trailingMargin={props.trailingMargin}
    src={props.src}
  />
);

CompanyThumbnail.propTypes = {
  alt: PropTypes.string,
  /** If the company doesn't have a thumbail, the API should already provide a placeholder */
  src: PropTypes.string.isRequired,
  /** Provided as px since that is what we read off of zeplin mockups */
  size: PropTypes.oneOf(["20px", "32px", "48px", "96px"]).isRequired,
  /** Add appropriate space to the right. Not mandatory because all spacing is situational */
  trailingMargin: PropTypes.bool,
};

CompanyThumbnail.defaultProps = {
  alt: "",
  trailingMargin: false,
};

export default CompanyThumbnail;
