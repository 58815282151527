import styled from "styled-components";

import { ColorPalette } from "../StylingConstants";

const elevation0 = { "background-color": ColorPalette.elevation0 };
const elevation1 = { "background-color": ColorPalette.elevation1 };
const elevation2 = { "background-color": ColorPalette.elevation2 };
const elevation3 = { "background-color": ColorPalette.elevation3 };
const elevation4 = { "background-color": ColorPalette.elevation4 };
const elevation5Flat = { "background-color": ColorPalette.elevation5 };
const elevation5Raised = {
  "background-color": ColorPalette.elevation4,
  "box-shadow": "0 2px 4px 0 rgba(0, 0, 0, 0.24), 0 1px 2px 0 rgba(0, 0, 0, 0.12)",
};

const StyledElevation0 = styled.div(elevation0);
const StyledElevation1 = styled.div(elevation1);
const StyledElevation2 = styled.div(elevation2);
const StyledElevation3 = styled.div(elevation3);
const StyledElevation4 = styled.div(elevation4);
const StyledElevation5 = styled.div(elevation5Raised);
const StyledElevation5Flat = styled.div(elevation5Flat);

export {
  elevation0,
  elevation1,
  elevation2,
  elevation3,
  elevation4,
  elevation5Flat,
  elevation5Raised,
  StyledElevation0,
  StyledElevation1,
  StyledElevation2,
  StyledElevation3,
  StyledElevation4,
  StyledElevation5,
  StyledElevation5Flat,
};
