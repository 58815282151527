/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_chat.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ChatIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M8,0 C3.5888,0 0,3.5888 0,8 C0,9.3824 0.36,10.736 1.0432,11.9392 L0.0408,14.9472 C-0.05400586,15.234522 0.0212174118,15.5507458 0.235243639,15.7646028 C0.449269867,15.9784599 0.765553108,16.0534331 1.0528,15.9584 L4.0608,14.956 C5.26098959,15.6400201 6.61857373,15.9998185 8,16 C12.4112,16 16,12.4112 16,8 C16,3.5888 12.4112,0 8,0 M8,14.4 C6.7903376,14.3990855 5.60587457,14.0542067 4.5848,13.4056 C4.38181827,13.2763701 4.13130757,13.2457913 3.9032,13.3224 L2.0648,13.9352 L2.6776,12.096 C2.75371839,11.8681081 2.72315949,11.618054 2.5944,11.4152 C1.94579327,10.3941254 1.60091448,9.2096624 1.6,8 C1.6,4.4712 4.4712,1.6 8,1.6 C11.5288,1.6 14.4,4.4712 14.4,8 C14.4,11.5288 11.5288,14.4 8,14.4 M8,7.2 C7.5581722,7.2 7.2,7.5581722 7.2,8 C7.2,8.4418278 7.5581722,8.8 8,8.8 C8.4418278,8.8 8.8,8.4418278 8.8,8 C8.8,7.5581722 8.4418278,7.2 8,7.2 M4.8,7.2 C4.3581722,7.2 4,7.5581722 4,8 C4,8.4418278 4.3581722,8.8 4.8,8.8 C5.2418278,8.8 5.6,8.4418278 5.6,8 C5.6,7.5581722 5.2418278,7.2 4.8,7.2 M11.2,7.2 C10.7581722,7.2 10.4,7.5581722 10.4,8 C10.4,8.4418278 10.7581722,8.8 11.2,8.8 C11.6418278,8.8 12,8.4418278 12,8 C12,7.5581722 11.6418278,7.2 11.2,7.2"
        transform=""
      />
    </g>
  </svg>
);

ChatIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ChatIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default ChatIcon;
