/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_chevron_down.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ChevronDownIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M11.2,15.5722301 C10.9714256,15.5726808 10.7537097,15.4747524 10.6024,15.3034286 L4.2024,8.10342857 C3.93295431,7.80054781 3.93295431,7.34390933 4.2024,7.04102857 L10.6024,-0.158971429 C10.7921795,-0.372473354 11.0814449,-0.468511119 11.3612327,-0.410908227 C11.6410205,-0.353305334 11.8688244,-0.150813034 11.9588327,0.120291781 C12.0488411,0.391396597 11.9873795,0.689926656 11.7976,0.903428571 L5.8696,7.57222857 L11.7976,14.2410286 C12.0074104,14.4762376 12.0593837,14.8127936 11.9303002,15.1003369 C11.8012168,15.3878802 11.515188,15.5727009 11.2,15.5722301"
        transform="translate(8.000364, 7.572439) rotate(-90.000000) translate(-8.000364, -7.572439) "
      />
    </g>
  </svg>
);

ChevronDownIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ChevronDownIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default ChevronDownIcon;
