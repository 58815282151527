/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_forward.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ForwardIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.1994237,7.59647659 L2.92822366,7.59647659 L7.72582366,3.39967659 C8.05579313,3.10829909 8.08836057,2.60508383 7.79870159,2.27360477 C7.5090426,1.94212572 7.00600335,1.90694392 6.67302366,2.19487659 L0.273023657,7.79487659 C0.266623657,7.80047659 0.264223657,7.80927659 0.257823657,7.81487659 C0.201057601,7.87123705 0.153299118,7.93599431 0.116223657,8.00687659 C0.101823657,8.03087659 0.0802236572,8.05007659 0.0690236572,8.07647659 C-0.0230078857,8.28044245 -0.0230078857,8.51411072 0.0690236572,8.71807659 C0.0802236572,8.74447659 0.101823657,8.76367659 0.116223657,8.78847659 C0.156223657,8.85807659 0.199423657,8.92447659 0.257823657,8.97967659 C0.264223657,8.98527659 0.266623657,8.99407659 0.273023657,8.99967659 L6.67302366,14.5996766 C6.83242528,14.7400992 7.0413009,14.8110561 7.25325312,14.7967858 C7.46520534,14.7825154 7.66268413,14.6841994 7.80182366,14.5236766 C8.09254023,14.1909782 8.05851421,13.6856021 7.72582366,13.3948766 L2.92822366,9.19647659 L15.1994237,9.19647659 C15.6412515,9.19647659 15.9994237,8.83830439 15.9994237,8.39647659 C15.9994237,7.95464879 15.6412515,7.59647659 15.1994237,7.59647659"
        transform="translate(7.999712, 8.399294) rotate(-180.000000) translate(-7.999712, -8.399294) "
      />
    </g>
  </svg>
);

ForwardIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ForwardIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default ForwardIcon;
