import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ColorPalette } from "../StylingConstants";
import { body1, FontColors, StyledCaption2 } from "../Typography";

const StyledLabel = styled.div`
  flex-grow: 1;
  ${body1}
  ${FontColors.light}
  ${props =>
    props.nowrap
      ? `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `
      : ""}
`;

const StyledWrapper = styled.div`
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  line-height: 1;
  min-width: 200px;
  padding: 0 12px;

  > svg path {
    fill: ${ColorPalette.lightWhite};
  }

  ${props =>
    props.tall
      ? `
    height: 64px;
  `
      : `
    height: 32px;
    margin-bottom: 4px;
  `}

  ${props =>
    props.selected
      ? `
    background: rgba(121, 134, 203, 0.5);
    ${StyledLabel} {
      color: @violetLight;
    }

    svg path {
      fill: @violetLight;
    }
  `
      : ""}

  a > &, .link > &${props => (props.onClick ? ", &" : "")} {
    cursor: pointer;

    &:hover,
    .hover-dropdown:hover & {
      background: ${ColorPalette.white05};

      ${StyledLabel} {
        color: ${ColorPalette.darkWhite};
      }

      svg path {
        fill: ${ColorPalette.darkWhite};
      }
    }

    &:active {
      background: ${ColorPalette.white10};
    }
  }
`;

const StyledIconWrapper = styled.div`
  padding-right: 12px;
  svg {
    height: 16px;
    width: 16px;
  }
`;

const StyledThumbnailWrapper = styled.div`
  padding-right: 12px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
`;

const StyledRightContent = styled.div`
  justify-self: flex-end;
`;

const StyledTextWrapper = styled.div`
  flex-grow: 1;
`;

/**
 * Yuka v2.0
 * List element
 *
 * @param {object} props
 * @returns {React.Element}
 */
const ListItem = props => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (props.active && ref.current) {
      ref.current.scrollIntoView({ block: "center" });
    }
  }, [props.active]);

  return (
    <StyledWrapper
      ref={ref}
      className={props.className}
      onClick={props.onClick}
      tall={Boolean(props.subtext)}
      selected={props.active}
    >
      {props.icon && <StyledIconWrapper>{props.icon}</StyledIconWrapper>}
      {props.thumbnail && <StyledThumbnailWrapper>{props.thumbnail}</StyledThumbnailWrapper>}
      <StyledTextWrapper>
        <StyledLabel nowrap={props.nowrap}>{props.text}</StyledLabel>
        {props.subtext && <StyledCaption2>{props.subtext}</StyledCaption2>}
      </StyledTextWrapper>
      <StyledRightContent>{props.children}</StyledRightContent>
    </StyledWrapper>
  );
};

ListItem.propTypes = {
  /* Item should appear in active state */
  active: PropTypes.bool,
  /* Icon appearing to left of text */
  icon: PropTypes.element,
  /* Whether the element should allow text to wrap or if it should ellipsis it */
  nowrap: PropTypes.bool,
  /* Click handler for the list item */
  onClick: PropTypes.func,
  /* subline for list item (if applicable) */
  subtext: PropTypes.node,
  /* Thumbnail appearing to left of text */
  thumbnail: PropTypes.element,
  /* text to display */
  text: PropTypes.node.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

ListItem.defaultProps = {
  active: false,
  icon: null,
  nowrap: true,
  onClick: null,
  subtext: "",
  thumbnail: null,
  children: null,
  className: "",
};

const StyledListItem = styled(ListItem)``;

StyledListItem.displayName = "ListItem";

/* @component */
export default StyledListItem;
