import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import BasicCard from "./BasicCard";
import { headline2, FontWeights, FontColors, StyledCaption2 } from "../Typography";

const StyledHeading = styled.div`
  position: relative;
  &:not(:last-child) {
    padding-bottom: 16px;
  }
`;

const StyledTitle = styled.div`
  ${headline2};
  ${FontWeights.bold};
  ${FontColors.brightest};
`;

const StyledTagline = styled(StyledCaption2)`
  margin-top: 4px;
`;

const StyledHeadingAction = styled.div`
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
`;

/**
 * Yuka v2.0
 * A rudimentary design component that renders a card at a specified elevation level with a title
 * and optionally a tagline
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const TitleCard = props => (
  <BasicCard elevation={props.elevation}>
    <StyledHeading>
      <StyledTitle>
        {props.title}
      </StyledTitle>
      {props.tagline && (
        <StyledTagline>{props.tagline}</StyledTagline>
      )}
      {props.action && <StyledHeadingAction>{props.action}</StyledHeadingAction>}
    </StyledHeading>
    {props.children}
  </BasicCard>
);

TitleCard.propTypes = {
  ...BasicCard.propTypes,
  action: PropTypes.node,
  title: PropTypes.node.isRequired,
  tagline: PropTypes.node,
  children: PropTypes.node,
};

TitleCard.defaultProps = {
  ...BasicCard.defaultProps,
  action: null,
  tagline: "",
  children: null,
};

export default TitleCard;
