/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_arrow_down_right.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ArrowDownRightIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.3813462,7.37881737 L3.11014621,7.37881737 L7.90774621,3.18201737 C8.23771568,2.89063987 8.27028313,2.38742461 7.98062414,2.05594555 C7.69096515,1.7244665 7.1879259,1.6892847 6.85494621,1.97721737 L0.45494621,7.57721737 C0.44854621,7.58281737 0.44614621,7.59161737 0.43974621,7.59721737 C0.382980154,7.65357783 0.335221671,7.71833509 0.29814621,7.78921737 C0.28374621,7.81321737 0.26214621,7.83241737 0.25094621,7.85881737 C0.158914667,8.06278323 0.158914667,8.2964515 0.25094621,8.50041737 C0.26214621,8.52681737 0.28374621,8.54601737 0.29814621,8.57081737 C0.33814621,8.64041737 0.38134621,8.70681737 0.43974621,8.76201737 C0.44614621,8.76761737 0.44854621,8.77641737 0.45494621,8.78201737 L6.85494621,14.3820174 C7.01434783,14.52244 7.22322346,14.5933969 7.43517567,14.5791266 C7.64712789,14.5648562 7.84460669,14.4665402 7.98374621,14.3060174 C8.27446279,13.973319 8.24043676,13.4679429 7.90774621,13.1772174 L3.11014621,8.97881737 L15.3813462,8.97881737 C15.823174,8.97881737 16.1813462,8.62064517 16.1813462,8.17881737 C16.1813462,7.73698957 15.823174,7.37881737 15.3813462,7.37881737"
        transform="translate(8.181634, 8.181634) rotate(-135.000000) translate(-8.181634, -8.181634) "
      />
    </g>
  </svg>
);

ArrowDownRightIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ArrowDownRightIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default ArrowDownRightIcon;
