/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_pin.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const PinIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M9.888,8.9864 C9.70538334,9.13850622 9.59985148,9.36393401 9.6,9.6016 C9.6,11.2 9.1704,12.0696 8.8352,12.504 L3.4976,7.168 C3.9376,6.828 4.8056,6.4016 6.4,6.4016 C6.63738611,6.40151099 6.86247289,6.29600156 7.0144,6.1136 L10.4544,1.9856 L14.0144,5.5472 L9.8872,8.9864 L9.888,8.9864 Z M15.7656,5.036 L10.9656,0.236 C10.8087329,0.0741204103 10.5890052,-0.0111993852 10.364,0.0024 C10.1392023,0.0119878161 9.92888553,0.115984232 9.7848,0.2888 L6.0192,4.808 C2.7368,4.932 1.7848,6.6416 1.684,6.8432 C1.5304,7.1512 1.5904,7.5232 1.8344,7.7672 L4.4688,10.4016 L0.2344,14.636 C0.0323305773,14.8380694 -0.0465865174,15.132592 0.0273760222,15.408624 C0.101338562,15.6846559 0.316944066,15.9002614 0.592976027,15.974224 C0.869007988,16.0481865 1.16353058,15.9692694 1.3656,15.7672 L5.6,11.5328 L8.2344,14.1672 C8.47790416,14.4104836 8.8497284,14.4707359 9.1576,14.3168 C9.3592,14.216 11.0696,13.2648 11.1928,9.9808 L15.7128,6.216 C15.8846315,6.07159303 15.9881236,5.86181648 15.9981569,5.63758723 C16.0081903,5.41335797 15.9238474,5.1951766 15.7656,5.036 Z"
        transform=""
      />
    </g>
  </svg>
);

PinIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

PinIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default PinIcon;
