// Icon Button styles based on Yuka 2 (May 2020) style guide
const IconButtonStyles = {
  // Corresponds to "small" in Yuka 2 design guidelines
  DEFAULT: "default",
  LARGE: "large",
  // Used for chips and selects. Technically not in Yuka
  TINY: "tiny",
};

export default IconButtonStyles;
