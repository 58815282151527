/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_circle_cross.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CircleCrossIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M8,0 C3.5888,0 0,3.5888 0,8 C0,12.4112 3.5888,16 8,16 C12.4112,16 16,12.4112 16,8 C16,3.5888 12.4112,0 8,0 M8,14.4 C4.4712,14.4 1.6,11.5288 1.6,8 C1.6,4.4712 4.4712,1.6 8,1.6 C11.5288,1.6 14.4,4.4712 14.4,8 C14.4,11.5288 11.5288,14.4 8,14.4 M10.9656,5.0344 C10.8156821,4.88422691 10.6121965,4.79983907 10.4,4.79983907 C10.1878035,4.79983907 9.98431795,4.88422691 9.8344,5.0344 L8,6.8688 L6.1656,5.0344 C5.96353058,4.83233058 5.66900799,4.75341348 5.39297603,4.82737602 C5.11694407,4.90133856 4.90133856,5.11694407 4.82737602,5.39297603 C4.75341348,5.66900799 4.83233058,5.96353058 5.0344,6.1656 L6.8688,8 L5.0344,9.8344 C4.83233057,10.0364694 4.75341347,10.330992 4.82737601,10.607024 C4.90133855,10.8830559 5.11694406,11.0986614 5.39297602,11.172624 C5.66900799,11.2465865 5.96353058,11.1676694 6.1656,10.9656 L8,9.1312 L9.8344,10.9656 C9.9841081,11.1161674 10.1876723,11.2008279 10.4,11.2008279 C10.6123277,11.2008279 10.8158919,11.1161674 10.9656,10.9656 C11.1157731,10.8156821 11.2001609,10.6121965 11.2001609,10.4 C11.2001609,10.1878035 11.1157731,9.98431795 10.9656,9.8344 L9.1312,8 L10.9656,6.1656 C11.1157731,6.01568205 11.2001609,5.81219648 11.2001609,5.6 C11.2001609,5.38780352 11.1157731,5.18431795 10.9656,5.0344"
        transform=""
      />
    </g>
  </svg>
);

CircleCrossIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CircleCrossIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default CircleCrossIcon;
