/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_folder_up.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const FolderUpIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.2,1.60000167 L7.5312,1.60000167 L6.1656,0.234401674 C6.015806,0.0840629468 5.81222601,-0.000306131655 5.6,8.34718994e-07 L0.8,8.34718994e-07 C0.3581722,8.34718994e-07 0,0.358173874 0,0.800001674 L0,15.2000017 C0,15.6418295 0.3581722,16.0000008 0.8,16.0000008 L15.2,16.0000008 C15.6418278,16.0000008 16,15.6418295 16,15.2000017 L16,2.40000167 C16,1.95817387 15.6418278,1.60000167 15.2,1.60000167 Z M5.0344,9.36560167 C5.18431795,9.51577476 5.38780352,9.6001626 5.6,9.6001626 C5.81219648,9.6001626 6.01568205,9.51577476 6.1656,9.36560167 L7.2,8.33120167 L7.2,11.2000017 C7.2,11.6418295 7.5581722,12.0000017 8,12.0000017 C8.4418278,12.0000017 8.8,11.6418295 8.8,11.2000017 L8.8,8.33120167 L9.8344,9.36560167 C9.9841081,9.51616907 10.1876723,9.60082956 10.4,9.60082956 C10.6123277,9.60082956 10.8158919,9.51616907 10.9656,9.36560167 C11.1157731,9.21568372 11.2001609,9.01219816 11.2001609,8.80000167 C11.2001609,8.58780519 11.1157731,8.38431962 10.9656,8.23440167 L8.5656,5.83440167 C8.41568205,5.68422859 8.21219648,5.59984074 8,5.59984074 C7.78780352,5.59984074 7.58431795,5.68422859 7.4344,5.83440167 L5.0344,8.23440167 C4.88422691,8.38431962 4.79983907,8.58780519 4.79983907,8.80000167 C4.79983907,9.01219816 4.88422691,9.21568372 5.0344,9.36560167 M14.4,14.4000017 L1.6,14.4000017 L1.6,1.60000167 L5.2688,1.60000167 L6.6344,2.96560167 C6.784194,3.1159404 6.98777399,3.20030948 7.2,3.20000167 L14.4,3.20000167 L14.4,14.4000017 Z"
        transform=""
      />
    </g>
  </svg>
);

FolderUpIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

FolderUpIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default FolderUpIcon;
