import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { IconButton } from "../Button";
import { CrossIcon } from "../Icons";
import { ColorPalette } from "../StylingConstants";
import { body1 } from "../Typography";

import BannerCardStyles from "./BannerCardStyles";

const STYLES = {
  // change as needed
  PROBLEM: BannerCardStyles.PROBLEM,
  WARNING: BannerCardStyles.WARNING,
  REWARD: BannerCardStyles.REWARD,
  INFORMATIONAL: BannerCardStyles.INFORMATIONAL,
};

const Z_INDEX = 4;

const StyledBanner = styled.div`
  ${body1}
  align-items: center;
  background-color: ${props => props.color};
  border-radius: 4px;
  justify-content: space-between;
  margin-top: 2%;
  padding: 12px 16px;
  position: absolute;
  z-index: ${Z_INDEX};
  margin-left: 15%;
  margin-right: 15%;
`;

const StyledCloseButton = styled(IconButton)`
  position: absolute;
  top: 0px;
  right: 0px;
`;

/**
 * Yuka v2.0
 * A rudimentary design component that renders a banner with a specified color
 * and text.
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const BannerCard = props => {
  const createHTML = text => ({ __html: text });

  const divId = `banner_${props.id}`;
  return (
    <StyledBanner id={divId} color={STYLES[props.color]}>
      <div
        id="banner_text"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={createHTML(props.text)}
      />
      <StyledCloseButton onClick={props.onClose}>
        <CrossIcon color={ColorPalette.fullWhite} size={12} />
      </StyledCloseButton>
    </StyledBanner>
  );
};

BannerCard.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BannerCard;
