/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_circle_check.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CircleCheckIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M8,0 C3.5888,0 0,3.5888 0,8 C0,12.4112 3.5888,16 8,16 C12.4112,16 16,12.4112 16,8 C16,3.5888 12.4112,0 8,0 M8,14.4 C4.4712,14.4 1.6,11.5288 1.6,8 C1.6,4.4712 4.4712,1.6 8,1.6 C11.5288,1.6 14.4,4.4712 14.4,8 C14.4,11.5288 11.5288,14.4 8,14.4 M9.8344,5.8344 L7.2,8.4688 L6.1656,7.4344 C5.85322775,7.12202775 5.34677225,7.12202775 5.0344,7.4344 C4.72202775,7.74677225 4.72202775,8.25322775 5.0344,8.5656 L6.6344,10.1656 C6.7841081,10.3161674 6.98767229,10.4008279 7.2,10.4008279 C7.41232771,10.4008279 7.6158919,10.3161674 7.7656,10.1656 L10.9656,6.9656 C11.1676694,6.76353058 11.2465865,6.46900799 11.172624,6.19297602 C11.0986614,5.91694406 10.8830559,5.70133855 10.607024,5.62737601 C10.330992,5.55341347 10.0364694,5.63233057 9.8344,5.8344"
        transform=""
      />
    </g>
  </svg>
);

CircleCheckIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CircleCheckIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default CircleCheckIcon;
