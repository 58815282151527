/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_security_protected.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const SecurityProtectedIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.272,2.1424 C15.1617685,1.81829856 14.8575341,1.60025325 14.5152,1.6 C14.484,1.6 11.3264,1.5824 8.3616,0.0856 C8.13451483,-0.0281494101 7.86708517,-0.0281494101 7.64,0.0856 C4.6896,1.5752 1.516,1.6 1.4848,1.6 C1.14217166,1.59991081 0.837526317,1.81802015 0.7272,2.1424 C-0.3664,5.3664 -0.2248,8.4024 1.1392,10.9184 C2.4568,13.352 4.9472,15.2416 7.8016,15.9744 C7.93202591,16.0078248 8.06877409,16.0078248 8.1992,15.9744 C11.0528,15.2416 13.5432,13.352 14.8616,10.9184 C16.224,8.4024 16.3664,5.3664 15.2728,2.1424 M9.8352,5.8344 L7.2,8.468 L6.1648,7.4344 C5.96258768,7.23218767 5.66785679,7.15321477 5.39162962,7.22722961 C5.11540244,7.30124446 4.89964446,7.51700244 4.82562961,7.79322962 C4.75161477,8.06945679 4.83058767,8.36418768 5.0328,8.5664 L6.6336,10.1664 C6.78344504,10.3170156 6.98714156,10.4016956 7.1996,10.4016956 C7.41205844,10.4016956 7.61575496,10.3170156 7.7656,10.1664 L10.9672,6.9664 C11.1692694,6.76418768 11.2481103,6.46953303 11.174024,6.19342962 C11.0999377,5.91732621 10.8841797,5.70172071 10.608024,5.62782962 C10.3318683,5.55393853 10.0372694,5.63298768 9.8352,5.8352 M13.4536,10.1584 C12.3744,12.1504 10.3464,13.7136 8.0008,14.3728 C5.6536,13.7136 3.6256,12.1504 2.5464,10.1584 C1.472,8.1744 1.3136,5.7704 2.0784,3.1768 C3.1664,3.1064 5.5848,2.8216 8.0008,1.6904 C10.4152,2.8216 12.8328,3.1064 13.9216,3.1768 C14.688,5.7704 14.528,8.1752 13.4536,10.1584"
        transform=""
      />
    </g>
  </svg>
);

SecurityProtectedIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

SecurityProtectedIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default SecurityProtectedIcon;
