/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_circle_question.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CircleQuestionIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M8,0 C3.5888,0 0,3.5888 0,8 C0,12.4112 3.5888,16 8,16 C12.4112,16 16,12.4112 16,8 C16,3.5888 12.4112,0 8,0 M8,14.4 C4.4712,14.4 1.6,11.5288 1.6,8 C1.6,4.4712 4.4712,1.6 8,1.6 C11.5288,1.6 14.4,4.4712 14.4,8 C14.4,11.5288 11.5288,14.4 8,14.4 M8,11.1199999 C7.68546337,11.1201429 7.39489578,11.2880782 7.23775122,11.5605464 C7.08060666,11.8330146 7.08075914,12.1686212 7.23815122,12.4409464 C7.3955433,12.7132717 7.68626337,12.8809429 8.0008,12.8808001 C8.48703149,12.8805791 8.8810209,12.4862315 8.88080007,12 C8.88057907,11.5137685 8.48623149,11.1197791 8,11.1199999 M8,3.2 C6.9,3.2 5.94,4.1288 5.8344,4.2344 C5.54636118,4.55010445 5.55698015,5.03635673 5.85852674,5.33918559 C6.16007333,5.64201444 6.64627617,5.65469661 6.9632,5.368 C7.196,5.1392 7.6864,4.8 8,4.8 C8.34603822,4.8004294 8.65265754,5.02307687 8.76017251,5.35198897 C8.86768747,5.68090106 8.75177435,6.04166616 8.4728,6.2464 C7.664,6.8384 7.2,7.6928 7.2,8.5896 L7.2,8.8 C7.2,9.2418278 7.5581722,9.6 8,9.6 C8.4418278,9.6 8.8,9.2418278 8.8,8.8 L8.8,8.5896 C8.8,8.0672 9.1872,7.7056 9.4184,7.536 C10.0340974,7.0828433 10.3983248,6.36448118 10.4,5.6 C10.4,4.2768 9.3232,3.2 8,3.2"
        transform=""
      />
    </g>
  </svg>
);

CircleQuestionIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CircleQuestionIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default CircleQuestionIcon;
