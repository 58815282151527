/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_delete.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const DeleteIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule=""
        d="M13.4571429,3.2 L11.0571429,3.2 L11.0571429,1.6 C11.0571429,0.7176 10.3395429,0 9.45714286,0 L6.25714286,0 C5.37474286,0 4.65714286,0.7176 4.65714286,1.6 L4.65714286,3.2 L2.25714286,3.2 C1.81531506,3.2 1.45714286,3.5581722 1.45714286,4 C1.45714286,4.4418278 1.81531506,4.8 2.25714286,4.8 L2.25714286,15.2 C2.25714286,15.6418278 2.61531506,16 3.05714286,16 L12.6571429,16 C13.0989707,16 13.4571429,15.6418278 13.4571429,15.2 L13.4571429,4.8 C13.8989707,4.8 14.2571429,4.4418278 14.2571429,4 C14.2571429,3.5581722 13.8989707,3.2 13.4571429,3.2 Z M3.85714286,14.4 L11.8571429,14.4 L11.8571429,4.8 L3.85714286,4.8 L3.85714286,14.4 Z M6.25714286,3.2 L9.45714286,3.2 L9.45714286,1.6 L6.25714286,1.6 L6.25714286,3.2 Z M6.25714286,12.8 C6.69897066,12.8 7.05714286,12.4418278 7.05714286,12 L7.05714286,7.2 C7.05714286,6.7581722 6.69897066,6.4 6.25714286,6.4 C5.81531506,6.4 5.45714286,6.7581722 5.45714286,7.2 L5.45714286,12 C5.45714286,12.4418278 5.81531506,12.8 6.25714286,12.8 M9.45714286,12.8 C9.89897066,12.8 10.2571429,12.4418278 10.2571429,12 L10.2571429,7.2 C10.2571429,6.7581722 9.89897066,6.4 9.45714286,6.4 C9.01531506,6.4 8.65714286,6.7581722 8.65714286,7.2 L8.65714286,12 C8.65714286,12.4418278 9.01531506,12.8 9.45714286,12.8"
        transform=""
      />
    </g>
  </svg>
);

DeleteIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

DeleteIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default DeleteIcon;
