/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_new_message.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const NewMessageIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule=""
        d="M7.2,-5.68434189e-14 C7.6418278,-5.68434189e-14 8,0.3581722 8,0.8 C8,1.2418278 7.6418278,1.6 7.2,1.6 L7.2,1.6 L1.6,1.6 L1.6,14.4 L14.4,14.4 L14.4,8.8 C14.4,8.3581722 14.7581722,8 15.2,8 C15.6418278,8 16,8.3581722 16,8.8 L16,8.8 L16,15.2 C16,15.6418278 15.6418278,16 15.2,16 L15.2,16 L0.8,16 C0.3581722,16 -1.77635684e-15,15.6418278 -1.77635684e-15,15.2 L-1.77635684e-15,15.2 L0,0.8 C0,0.3581722 0.3581722,-6.03961325e-14 0.8,-6.03961325e-14 L0.8,-6.03961325e-14 Z M15.0897823,0.741598045 C15.5377823,1.17039804 15.7921823,1.76239804 15.7937823,2.38159804 C15.7521501,3.21743043 15.3810761,4.00272659 14.7617823,4.56559804 L12.3857823,6.94959804 L11.5697823,7.76559804 C11.464869,7.86671957 11.3351941,7.9384546 11.1937823,7.97359804 L7.99378231,8.77359804 C7.72151299,8.84133954 7.43361054,8.76254518 7.23378231,8.56559804 C7.03367532,8.36760338 6.95438765,8.07789843 7.02578231,7.80559804 L7.82578231,4.60559804 C7.8576314,4.46291437 7.92983283,4.33239639 8.03378231,4.22959804 L11.2257823,1.02959804 C11.6655949,0.574690893 12.217423,0.243594021 12.8257823,0.069598045 C13.6465271,-0.136001497 14.5141161,0.121516094 15.0897823,0.741598045 Z M9.37778231,5.14959804 L9.32178231,5.20559804 L8.89778231,6.90159804 L10.5937823,6.47759804 L10.6657823,6.40559804 L9.37778231,5.14959804 Z M13.4017823,1.59759804 C13.336981,1.59669202 13.2723667,1.60476881 13.2097823,1.62159804 C12.8857823,1.72799804 12.5937823,1.91519804 12.3617823,2.16559804 L10.5057823,4.02159804 L11.7937823,5.26959804 L13.6257823,3.42959804 C13.9137823,3.14959804 14.5137823,2.42959804 13.9617823,1.86959804 C13.8216311,1.70426743 13.6183774,1.60554424 13.4017823,1.59759804 Z"
        transform=""
      />
    </g>
  </svg>
);

NewMessageIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

NewMessageIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default NewMessageIcon;
