/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_undo.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const UndoIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M9.20016093,2.4 L2.73136093,2.4 L3.76576093,1.3656 C3.96783035,1.16353058 4.04674745,0.869007988 3.97278491,0.592976027 C3.89882237,0.316944066 3.68321686,0.101338562 3.4071849,0.0273760222 C3.13115294,-0.0465865174 2.83663035,0.0323305773 2.63456093,0.2344 L0.234560929,2.6344 C0.0843878426,2.78431795 -3.01980663e-14,2.98780352 -3.01980663e-14,3.2 C-3.01980663e-14,3.41219648 0.0843878426,3.61568205 0.234560929,3.7656 L2.63456093,6.1656 C2.78426903,6.31616739 2.98783322,6.40082788 3.20016093,6.40082788 C3.41248864,6.40082788 3.61605283,6.31616739 3.76576093,6.1656 C3.91593402,6.01568205 4.00032186,5.81219648 4.00032186,5.6 C4.00032186,5.38780352 3.91593402,5.18431795 3.76576093,5.0344 L2.73136093,4 L9.20016093,4 C12.0673609,4 14.4001609,6.3328 14.4001609,9.2 C14.4001609,12.0672 12.0673609,14.4 9.20016093,14.4 C6.32974215,14.3964737 4.00368725,12.0704188 4.00016093,9.2 L4.00016093,8.8 C4.00016093,8.3581722 3.64198873,8 3.20016093,8 C2.75833313,8 2.40016093,8.3581722 2.40016093,8.8 L2.40016093,9.2 C2.40016093,12.9496 5.45056093,16 9.20016093,16 C12.9497609,16 16.0001609,12.9496 16.0001609,9.2 C16.0001609,5.4504 12.9497609,2.4 9.20016093,2.4"
        transform=""
      />
    </g>
  </svg>
);

UndoIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

UndoIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default UndoIcon;
