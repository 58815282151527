/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_mention.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const MentionIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M8,10.4 C6.6768,10.4 5.6,9.3232 5.6,8 C5.6,6.6768 6.6768,5.6 8,5.6 C9.3232,5.6 10.4,6.6768 10.4,8 C10.4,9.3232 9.3232,10.4 8,10.4 M8,0 C3.5888,0 0,3.5888 0,8 C0,12.4112 3.5888,16 8,16 C8.4418278,16 8.8,15.6418278 8.8,15.2 C8.8,14.7581722 8.4418278,14.4 8,14.4 C4.4712,14.4 1.6,11.5288 1.6,8 C1.6,4.4712 4.4712,1.6 8,1.6 C11.5288,1.6 14.4,4.4712 14.4,8 L14.4,8.8 C14.4,9.4616 13.8616,10 13.2,10 C12.5384,10 12,9.4616 12,8.8 L12,8 C12,5.7944 10.2056,4 8,4 C5.7944,4 4,5.7944 4,8 C4,10.2056 5.7944,12 8,12 C9.2248,12 10.3096,11.4344 11.044,10.564 C11.5699098,11.2164814 12.3619638,11.5970789 13.2,11.6 C14.744,11.6 16,10.344 16,8.8 L16,8 C16,3.5888 12.4112,0 8,0"
        transform=""
      />
    </g>
  </svg>
);

MentionIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

MentionIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default MentionIcon;
