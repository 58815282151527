/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_search.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const SearchIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.7656,14.6344 L11.4496,10.3176 C12.3243445,9.19904334 12.7997083,7.81998126 12.8,6.4 C12.8,2.8712 9.9288,1.59872116e-14 6.4,1.59872116e-14 C2.8712,1.59872116e-14 2.66453526e-14,2.8712 2.66453526e-14,6.4 C2.66453526e-14,9.9288 2.8712,12.8 6.4,12.8 C7.81998126,12.7997083 9.19904334,12.3243445 10.3176,11.4496 L14.6344,15.7656 C14.7841081,15.9161674 14.9876723,16.0008279 15.2,16.0008279 C15.4123277,16.0008279 15.6158919,15.9161674 15.7656,15.7656 C15.9157731,15.6156821 16.0001609,15.4121965 16.0001609,15.2 C16.0001609,14.9878035 15.9157731,14.7843179 15.7656,14.6344 M6.4,11.2 C3.7528,11.2 1.6,9.0472 1.6,6.4 C1.6,3.7528 3.7528,1.6 6.4,1.6 C9.0472,1.6 11.2,3.7528 11.2,6.4 C11.2,9.0472 9.0472,11.2 6.4,11.2"
        transform=""
      />
    </g>
  </svg>
);

SearchIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

SearchIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default SearchIcon;
