/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_circle_alert.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CircleAlertIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M8,0 C3.5888,0 0,3.5888 0,8 C0,12.4112 3.5888,16 8,16 C12.4112,16 16,12.4112 16,8 C16,3.5888 12.4112,0 8,0 M8,14.4 C4.4712,14.4 1.6,11.5288 1.6,8 C1.6,4.4712 4.4712,1.6 8,1.6 C11.5288,1.6 14.4,4.4712 14.4,8 C14.4,11.5288 11.5288,14.4 8,14.4 M8,8.8 C8.4418278,8.8 8.8,8.4418278 8.8,8 L8.8,4.8 C8.8,4.3581722 8.4418278,4 8,4 C7.5581722,4 7.2,4.3581722 7.2,4.8 L7.2,8 C7.2,8.4418278 7.5581722,8.8 8,8.8 M8,10.32 C7.51398942,10.32 7.12,10.7139894 7.12,11.2 C7.12,11.6860106 7.51398942,12.08 8,12.08 C8.48601058,12.08 8.88,11.6860106 8.88,11.2 C8.88,10.7139894 8.48601058,10.32 8,10.32"
        transform=""
      />
    </g>
  </svg>
);

CircleAlertIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CircleAlertIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default CircleAlertIcon;
