/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_caret_left.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CaretLeftIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon
        fill={color}
        fillRule="nonzero"
        points="2.5 5.5 7.5 10.5 12.5 5.5"
        transform="translate(7.500000, 8.000000) rotate(-270.000000) translate(-7.500000, -8.000000) "
      />
    </g>
  </svg>
);

CaretLeftIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CaretLeftIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default CaretLeftIcon;
