/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_logout.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const LogoutIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule=""
        d="M8,14 C8.55228475,14 9,14.4477153 9,15 C9,15.5522847 8.55228475,16 8,16 C3.589,16 0,12.411 0,8 C0,3.589 3.589,0 8,0 C8.55228475,0 9,0.44771525 9,1 C9,1.55228475 8.55228475,2 8,2 C4.691,2 2,4.691 2,8 C2,11.309 4.691,14 8,14 Z M15.707,7.293 C15.8947164,7.48039744 16.0002012,7.7347544 16.0002012,8 C16.0002012,8.2652456 15.8947164,8.51960256 15.707,8.707 L12.707,11.707 C12.5198649,11.8952092 12.2654096,12.0010349 12,12.0010349 C11.7345904,12.0010349 11.4801351,11.8952092 11.293,11.707 C11.1052836,11.5196026 10.9997988,11.2652456 10.9997988,11 C10.9997988,10.7347544 11.1052836,10.4803974 11.293,10.293 L12.586,9 L8,9 C7.44771525,9 7,8.55228475 7,8 C7,7.44771525 7.44771525,7 8,7 L12.586,7 L11.293,5.707 C10.9025347,5.31653468 10.9025347,4.68346532 11.293,4.293 C11.6834653,3.90253468 12.3165347,3.90253468 12.707,4.293 L15.707,7.293 Z"
        transform=""
      />
    </g>
  </svg>
);

LogoutIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

LogoutIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default LogoutIcon;
