import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { CaretDownIcon, CaretRightIcon } from "../Icons";
import { ColorPalette } from "../StylingConstants";
import { FontColors, overline } from "../Typography";

const StyledSection = styled.div.attrs({ className: "collapsible-section" })`
  margin: 24px 0px 0px 0px;
`;

const StyledHeader = styled.div`
  ${overline}
  ${FontColors.faint}
  cursor: pointer;
  margin: 12px 24px;

  svg {
    margin-right: 8px;
  }
`;

const StyledHeaderTitle = styled.span`
  vertical-align: text-top;
`;

/**
 * Yuka v1.0
 * Renders a labelled section that can be opened and closed.
 *
 * @param {object} props
 * @returns {React.Element}
 */
const CollapsibleSection = props => {
  const [collapsed, setCollapsed] = useState(props.defaultCollapsed);
  const { onCollapseToggle, sectionTitle } = props;

  const toggleCollapsed = useCallback(() => {
    if (onCollapseToggle) {
      onCollapseToggle(!collapsed);
    }
    setCollapsed(!collapsed);
  }, [collapsed, onCollapseToggle]);

  const headerComponent = props.headerComponent ? (
    <props.headerComponent
      sectionTitle={sectionTitle}
      onClick={toggleCollapsed}
      collapsed={collapsed}
    />
  ) : (
    <StyledHeader onClick={toggleCollapsed}>
      {collapsed ? (
        <CaretRightIcon color={ColorPalette.lightWhite} size={12} />
      ) : (
        <CaretDownIcon color={ColorPalette.lightWhite} size={12} />
      )}
      <StyledHeaderTitle>{props.sectionTitle}</StyledHeaderTitle>
    </StyledHeader>
  );

  return (
    <StyledSection>
      {headerComponent}
      {collapsed ? null : props.children}
    </StyledSection>
  );
};

CollapsibleSection.propTypes = {
  defaultCollapsed: PropTypes.bool,
  sectionTitle: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onCollapseToggle: PropTypes.func,
  headerComponent: PropTypes.func,
};

CollapsibleSection.defaultProps = {
  defaultCollapsed: false,
  onCollapseToggle: null,
  headerComponent: null,
};

export default CollapsibleSection;
