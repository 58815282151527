import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import styled from "styled-components";

import TabItem from "./TabItem";
import useTabRoute from "./useTabRoute";

import { ColorPalette } from "../StylingConstants";

const StyledTabList = styled.div.attrs({ className: "tab-list" })`
  border-bottom: 1px solid ${ColorPalette.minWhite};
  margin-bottom: 24px;
`;

/**
 * Yuka v2.0
 * This component renders a list of card tabs and calls the provided change handler with the name
 * of the tab clicked when clicked.
 *
 * @param {object} props
 * @returns {React.Component}
 */
const TabList = props => {
  const [currentTab, handleChangeTab] = useTabRoute(
    props.tabNames,
    props.paramName,
    props.defaultTab
  );

  const handleTabClick = name => {
    props.onTabChange(name);
    handleChangeTab(name);
  };

  return (
    <StyledTabList>
      {_.map(props.tabNames, name => (
        <TabItem
          active={currentTab === name}
          handleTabClick={_.partial(handleTabClick, name)}
          key={name}
          showNotification={_.get(props.showNotifications, name, false)}
        >
          {props.renderLabel(name, currentTab === name)}
        </TabItem>
      ))}
    </StyledTabList>
  );
};

TabList.propTypes = {
  onTabChange: PropTypes.func,
  tabNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  showNotifications: PropTypes.objectOf(PropTypes.bool),
  renderLabel: PropTypes.func,
  paramName: PropTypes.string,
  defaultTab: PropTypes.string,
};

TabList.defaultProps = {
  onTabChange: _.noop,
  showNotifications: {},
  renderLabel: label => label,
  paramName: "tab",
  defaultTab: null,
};

export default TabList;
