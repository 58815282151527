/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_notification.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const NotificationIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M12.6571429,6.4 C12.6571429,4.0264 10.9235429,2.0544 8.65714286,1.672 L8.65714286,0.8 C8.65714286,0.3581722 8.29897066,0 7.85714286,0 C7.41531506,0 7.05714286,0.3581722 7.05714286,0.8 L7.05714286,1.672 C4.79074286,2.0544 3.05714286,4.0264 3.05714286,6.4 C3.05714286,8.7656 3.05714286,10.0688 1.69154286,11.4344 C1.46281652,11.6631954 1.39440533,12.0072293 1.51820047,12.3061236 C1.64199562,12.605018 1.93362614,12.7999309 2.25714286,12.8 L5.60354286,12.8 C5.50816402,13.0560064 5.45860744,13.3268073 5.45714286,13.6 C5.45714286,14.9232 6.53314286,16 7.85714286,16 C9.18114286,16 10.2571429,14.9232 10.2571429,13.6 C10.2571429,13.3176 10.2011429,13.0504 10.1099429,12.8 L13.4571429,12.8 C13.7806596,12.7999309 14.0722901,12.605018 14.1960852,12.3061236 C14.3198804,12.0072293 14.2514692,11.6631954 14.0227429,11.4344 C12.6571429,10.0688 12.6571429,8.7656 12.6571429,6.4 Z M3.86594286,11.2 C4.65714286,9.7664 4.65714286,8.228 4.65714286,6.4 C4.65714286,4.6352 6.09234286,3.2 7.85714286,3.2 C9.62194286,3.2 11.0571429,4.6352 11.0571429,6.4 C11.0571429,8.228 11.0571429,9.7664 11.8483429,11.2 L3.86594286,11.2 Z M8.65714286,13.6 C8.63800066,14.0279256 8.28549635,14.365014 7.85714286,14.365014 C7.42878936,14.365014 7.07628505,14.0279256 7.05714286,13.6 C7.05714286,13.1581722 7.41531506,12.8 7.85714286,12.8 C8.29897066,12.8 8.65714286,13.1581722 8.65714286,13.6 L8.65714286,13.6 Z"
        transform=""
      />
    </g>
  </svg>
);

NotificationIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

NotificationIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default NotificationIcon;
