/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_code.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CodeIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M2.4,7.2 C2.39787115,7.41120094 2.31147044,7.61280259 2.16,7.76 L1.928,8 L2.168,8.24 C2.31579533,8.38900851 2.3991163,8.5901281 2.4,8.8 L2.4,12 C2.4,12.4418278 2.7581722,12.8 3.2,12.8 C3.6418278,12.8 4,13.1581722 4,13.6 C4,14.0418278 3.6418278,14.4 3.2,14.4 C1.8745166,14.4 0.8,13.3254834 0.8,12 L0.8,9.128 L0.24,8.568 C0.0885498793,8.41778672 0.00336134053,8.21331003 0.00336134053,8 C0.00336134053,7.78668997 0.0885498793,7.58221328 0.24,7.432 L0.8,6.872 L0.8,4 C0.8,2.6745166 1.8745166,1.6 3.2,1.6 C3.6418278,1.6 4,1.9581722 4,2.4 C4,2.8418278 3.6418278,3.2 3.2,3.2 C2.7581722,3.2 2.4,3.5581722 2.4,4 L2.4,7.2 L2.4,7.2 Z M15.76,7.44 C16.0649143,7.75107481 16.0649143,8.24892519 15.76,8.56 L15.2,9.128 L15.2,12 C15.2,13.3254834 14.1254834,14.4 12.8,14.4 C12.3581722,14.4 12,14.0418278 12,13.6 C12,13.1581722 12.3581722,12.8 12.8,12.8 C13.2418278,12.8 13.6,12.4418278 13.6,12 L13.6,8.8 C13.6021289,8.58879906 13.6885296,8.38719741 13.84,8.24 L14.072,8 L13.832,7.76 C13.6842047,7.61099149 13.6008837,7.4098719 13.6,7.2 L13.6,4 C13.6,3.5581722 13.2418278,3.2 12.8,3.2 C12.3581722,3.2 12,2.8418278 12,2.4 C12,1.9581722 12.3581722,1.6 12.8,1.6 C14.1254834,1.6 15.2,2.6745166 15.2,4 L15.2,6.872 L15.76,7.432 L15.76,7.44 Z M9.896,3.256 C10.0934461,3.33434809 10.2516258,3.48800636 10.3356653,3.68309805 C10.4197047,3.87818973 10.4227034,4.0986952 10.344,4.296 L7.144,12.296 C7.02269816,12.6005768 6.72784308,12.8003174 6.4,12.8000004 C6.13461589,12.800257 5.88637763,12.6688921 5.73732482,12.4493197 C5.588272,12.2297472 5.55780771,11.9505503 5.656,11.704 L8.856,3.704 C8.93434809,3.50655385 9.08800636,3.34837424 9.28309805,3.26433475 C9.47818973,3.18029525 9.6986952,3.17729664 9.896,3.256 L9.896,3.256 Z"
        transform=""
      />
    </g>
  </svg>
);

CodeIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CodeIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default CodeIcon;
