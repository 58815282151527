/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_face_sad.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const FaceSadIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M8,0 C3.5888,0 0,3.5888 0,8 C0,12.4112 3.5888,16 8,16 C12.4112,16 16,12.4112 16,8 C16,3.5888 12.4112,0 8,0 M8,14.4 C4.4712,14.4 1.6,11.5288 1.6,8 C1.6,4.4712 4.4712,1.6 8,1.6 C11.5288,1.6 14.4,4.4712 14.4,8 C14.4,11.5288 11.5288,14.4 8,14.4 M5.6,8 C6.0418278,8 6.4,7.6418278 6.4,7.2 C6.4,6.7581722 6.0418278,6.4 5.6,6.4 C5.1581722,6.4 4.8,6.7581722 4.8,7.2 C4.8,7.6418278 5.1581722,8 5.6,8 M10.4,6.4 C9.9581722,6.4 9.6,6.7581722 9.6,7.2 C9.6,7.6418278 9.9581722,8 10.4,8 C10.8418278,8 11.2,7.6418278 11.2,7.2 C11.2,6.7581722 10.8418278,6.4 10.4,6.4 M8,9.6 C7.8024,9.6 6.0392,9.6288 5.0344,10.6344 C4.72202775,10.9467723 4.72202775,11.4532277 5.0344,11.7656 C5.34677225,12.0779723 5.85322775,12.0779723 6.1656,11.7656 C6.6216,11.3096 7.6704,11.2016 7.9976,11.2 C8.3296,11.2016 9.3784,11.3096 9.8344,11.7656 C9.9841081,11.9161674 10.1876723,12.0008279 10.4,12.0008279 C10.6123277,12.0008279 10.8158919,11.9161674 10.9656,11.7656 C11.1157731,11.6156821 11.2001609,11.4121965 11.2001609,11.2 C11.2001609,10.9878035 11.1157731,10.7843179 10.9656,10.6344 C9.9608,9.6288 8.1976,9.6 8,9.6"
        transform=""
      />
    </g>
  </svg>
);

FaceSadIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

FaceSadIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default FaceSadIcon;
