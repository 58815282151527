import styled from "styled-components";

import { ColorPalette } from "../StylingConstants";

const size11 = "0.6875rem";
const size12 = "0.75rem";
const size14 = "0.875rem";
const size16 = "1rem";
const size18 = "1.125rem";
const size20 = "1.25rem";
const size24 = "1.5rem";
const size34 = "2.125rem";
const size48 = "3rem";

// const weightLight = '300'; // Unused
const weightNormal = "400";
const weightMedium = "500";
const weightBold = "700";

const lineHeightTitle = "1.25";
const lineHeightNormal = "1.5";
const lineHeightMinimum = "1";

const letterSpacing1 = "0";
const letterSpacing2 = "0.015625rem";
const letterSpacing3 = "0.009375rem";
const letterSpacing4 = "0.00625rem";
const letterSpacing5 = "0.025rem";
const letterSpacing6 = "0.0625rem";

const headline5 = {
  "font-size": size48,
  "font-weight": weightNormal,
  "line-height": lineHeightTitle,
  "letter-spacing": letterSpacing1,
};

const headline4 = {
  "font-size": size34,
  "font-weight": weightNormal,
  "line-height": lineHeightTitle,
  "letter-spacing": letterSpacing2,
};

const headline3 = {
  "font-size": size24,
  "font-weight": weightNormal,
  "line-height": lineHeightTitle,
  "letter-spacing": letterSpacing1,
};

const headline2 = {
  "font-size": size20,
  "font-weight": weightNormal,
  "line-height": lineHeightTitle,
  "letter-spacing": letterSpacing3,
};

const headline1 = {
  "font-size": size18,
  "font-weight": weightNormal,
  "line-height": lineHeightTitle,
  "letter-spacing": letterSpacing3,
};

const body2 = {
  "font-size": size16,
  "font-weight": weightNormal,
  "line-height": lineHeightTitle,
  "letter-spacing": letterSpacing3,
};

const body1 = {
  "font-size": size14,
  "font-weight": weightNormal,
  "line-height": lineHeightNormal,
  "letter-spacing": letterSpacing4,
};

const caption2 = {
  "font-size": size12,
  "font-weight": weightNormal,
  "line-height": lineHeightNormal,
};

const caption1 = {
  "font-size": size11,
  "font-weight": weightNormal,
  "line-height": lineHeightMinimum,
  "letter-spacing": letterSpacing5,
};

const overline = {
  "font-size": size11,
  "font-weight": weightNormal,
  "line-height": lineHeightMinimum,
  "letter-spacing": letterSpacing6,
  "text-transform": "uppercase",
};

const TextModifiers = {
  transformUpper: { "text-transform": "uppercase" },

  transformLower: { "text-transform": "lowercase" },
  strikethrough: { "text-decoration": "line-through" },
  underline: { "text-decoration": "underline" },
};

const FontWeights = {
  medium: { "font-weight": weightMedium },
  normal: { "font-weight": weightNormal },
  bold: { "font-weight": weightBold },
};

const FontColors = {
  min: { color: ColorPalette.minWhite },
  faint: { color: ColorPalette.faintWhite },
  light: { color: ColorPalette.lightWhite },
  bright: { color: ColorPalette.darkWhite },
  brightest: { color: ColorPalette.fullWhite },
  green: { color: ColorPalette.greenNormal },
  red: { color: ColorPalette.redDark },
  normalRed: { color: ColorPalette.redNormal },
  lightGreen: { color: ColorPalette.greenLight },
  yellow: { color: ColorPalette.yellowLight },
  violet: { color: ColorPalette.violetNormal },
};

const StyledHeadline1 = styled.h1(headline1);
const StyledHeadline2 = styled.h2(headline2);
const StyledHeadline3 = styled.h3(headline3);
const StyledHeadline4 = styled.h4(headline4);
const StyledHeadline5 = styled.h5(headline5);
const StyledBody1 = styled.span(body1);
const StyledBody2 = styled.span(body2);
const StyledCaption1 = styled.span({ ...caption1, ...FontColors.light });
const StyledCaption2 = styled.span({ ...caption2, ...FontColors.light });
const StyledOverline = styled.span({ ...overline, ...FontColors.faint });

export {
  headline1,
  headline2,
  headline3,
  headline4,
  headline5,
  body1,
  body2,
  caption1,
  caption2,
  overline,
  TextModifiers,
  FontWeights,
  FontColors,
  StyledHeadline1,
  StyledHeadline2,
  StyledHeadline3,
  StyledHeadline4,
  StyledHeadline5,
  StyledBody1,
  StyledBody2,
  StyledCaption1,
  StyledCaption2,
  StyledOverline,
  size14,
  size12,
};
