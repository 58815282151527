import { ColorPalette } from "../StylingConstants";

const BannerCardStyles = {
  PROBLEM: ColorPalette.redDark,
  WARNING: ColorPalette.yellowLight,
  REWARD: ColorPalette.greenDark,
  INFORMATIONAL: ColorPalette.violetDark,
};

export default BannerCardStyles;
