/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_view.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ViewIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.9696,7.78 C15.952,7.7184 14.1328,1.6 8,1.6 C1.8672,1.6 0.048,7.7184 0.0304,7.78 C-0.0096,7.924 -0.0096,8.076 0.0304,8.22 C0.048,8.2816 1.8672,14.4 8,14.4 C14.1328,14.4 15.952,8.2816 15.9696,8.22 C16.0096,8.076 16.0096,7.924 15.9696,7.78 Z M8,12.8 C3.6264,12.8 1.9904,8.9824 1.6416,8 C1.9904,7.0176 3.6256,3.2 8,3.2 C12.376,3.2 14.0112,7.0208 14.3584,8 C14.0104,8.9824 12.3744,12.8 8,12.8 Z M8,9.6 C7.1176,9.6 6.4,8.8824 6.4,8 C6.4,7.1176 7.1176,6.4 8,6.4 C8.8824,6.4 9.6,7.1176 9.6,8 C9.6,8.8824 8.8824,9.6 8,9.6 Z M8,4.8 C6.2352,4.8 4.8,6.2352 4.8,8 C4.8,9.7648 6.2352,11.2 8,11.2 C9.7648,11.2 11.2,9.7648 11.2,8 C11.2,6.2352 9.7648,4.8 8,4.8 Z"
        transform=""
      />
    </g>
  </svg>
);

ViewIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ViewIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default ViewIcon;
