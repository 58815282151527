/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_down.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const DownIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.1994237,7.19689481 L2.92822366,7.19689481 L7.72582366,3.00009481 C8.05579313,2.70871732 8.08836057,2.20550205 7.79870159,1.874023 C7.5090426,1.54254394 7.00600335,1.50736215 6.67302366,1.79529481 L0.273023657,7.39529481 C0.266623657,7.40089481 0.264223657,7.40969481 0.257823657,7.41529481 C0.201057601,7.47165527 0.153299118,7.53641254 0.116223657,7.60729481 C0.101823657,7.63129481 0.0802236572,7.65049481 0.0690236572,7.67689481 C-0.0230078857,7.88086068 -0.0230078857,8.11452895 0.0690236572,8.31849481 C0.0802236572,8.34489481 0.101823657,8.36409481 0.116223657,8.38889481 C0.156223657,8.45849481 0.199423657,8.52489481 0.257823657,8.58009481 C0.264223657,8.58569481 0.266623657,8.59449481 0.273023657,8.60009481 L6.67302366,14.2000948 C6.83242528,14.3405174 7.0413009,14.4114744 7.25325312,14.397204 C7.46520534,14.3829337 7.66268413,14.2846176 7.80182366,14.1240948 C8.09254023,13.7913964 8.05851421,13.2860204 7.72582366,12.9952948 L2.92822366,8.79689481 L15.1994237,8.79689481 C15.6412515,8.79689481 15.9994237,8.43872261 15.9994237,7.99689481 C15.9994237,7.55506701 15.6412515,7.19689481 15.1994237,7.19689481"
        transform="translate(7.999712, 7.999712) rotate(-90.000000) translate(-7.999712, -7.999712) "
      />
    </g>
  </svg>
);

DownIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

DownIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default DownIcon;
