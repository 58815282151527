/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";

/**
 * Yuka v1.0
 *
 * Placeholder for Avatar. This is a holdover from Private Label and can be deprecated
 * once a suitable replacement is provided
 *
 * @param {object} props
 * @returns {React.Element}
 */
const DefaultProfileAvatar = ({ size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard">
        <g id="ic_default_user">
          <circle id="circle" fill="#B5B3B6" cx="8" cy="8" r="8" />
          <g id="Group" transform="translate(2.000000, 2.000000)">
            <polygon id="Bounds" fill="#B5B3B6" opacity="0" points="0 0 12 0 12 12 0 12" />
            <path
              d="M6,2.95 C6.58,2.95 7.05,3.42 7.05,4 C7.05,4.58 6.58,5.05 6,5.05 C5.42,5.05 4.95,4.58 4.95,4 C4.95,3.42 5.42,2.95 6,2.95 L6,2.95 Z M6,7.45 C7.485,7.45 9.05,8.18 9.05,8.5 L9.05,9.05 L2.95,9.05 L2.95,8.5 C2.95,8.18 4.515,7.45 6,7.45 L6,7.45 Z M6,2 C4.895,2 4,2.895 4,4 C4,5.105 4.895,6 6,6 C7.105,6 8,5.105 8,4 C8,2.895 7.105,2 6,2 L6,2 Z M6,6.5 C4.665,6.5 2,7.17 2,8.5 L2,10 L10,10 L10,8.5 C10,7.17 7.335,6.5 6,6.5 L6,6.5 Z"
              id="Shape"
              fillOpacity="0.3"
              fill="#000000"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

DefaultProfileAvatar.propTypes = {
  size: PropTypes.string,
};

DefaultProfileAvatar.defaultProps = {
  size: "32px",
};

DefaultProfileAvatar.displayName = "DefaultProfileAvatar";

export default DefaultProfileAvatar;
