import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import styled, { css } from "styled-components";

import { ColorPalette } from "../StylingConstants";

const FONT_SIZES = {
  "14px": "8px",
  "20px": "10px",
  "32px": "16px",
  "40px": "20px",
  "48px": "24px",
  "96px": "48px",
};

// As given by design. Hard-coded here because they don't generally correspond
// other colors on the site
const DEFAULT_BACKGROUND_COLORS = [
  "#7986cb", // purple
  "#f088b0", // pink
  "#ffa086", // orange
  "#f3cc7d", // yellow
  "#79cbc2", // teal
];

const TRAILING_MARGIN = {
  "20px": "8px",
  "32px": "12px",
  "96px": "24px",
};

const styles = css`
  background-color: ${ColorPalette.faintBlack};
  border-radius: 50%;
  box-sizing: border-box;
  color: ${ColorPalette.fullWhite};
  display: flex;
  font-weight: bold;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${props =>
    props.pointer
      ? `
    cursor: pointer;
  `
      : ""}
`;

const StyledDefaultAvatar = styled.div`
  ${styles}
`;
const StyledAvatar = styled.img`
  ${styles}
`;

/**
 * Yuka v2.0
 * This returns a circular avatar wrapped in a container based on the dimensions.
 * By default, this will return a gray background avatar with the user's initials.
 *
 * @param {object} props
 *
 * @returns {React.Component}
 */
const Avatar = props => {
  const fontSize = _.get(FONT_SIZES, props.dimension);
  if (props.useDefault) {
    return (
      <StyledDefaultAvatar
        pointer={props.onClick !== _.noop}
        onClick={props.onClick}
        style={{
          fontSize, // This is for the default initials text
          lineHeight: props.dimension, // Vertically center text
          height: props.dimension,
          width: props.dimension,
          backgroundColor:
            DEFAULT_BACKGROUND_COLORS[props.colorId % DEFAULT_BACKGROUND_COLORS.length],
          marginRight: props.trailingMargin ? TRAILING_MARGIN[props.dimension] : null,
        }}
      >
        {props.avatarAlt}
      </StyledDefaultAvatar>
    );
  }

  return (
    <StyledAvatar
      alt={props.avatarAlt}
      onClick={props.onClick}
      src={props.avatar}
      style={{
        fontSize, // This is strictly for alt text
        height: props.dimension,
        width: props.dimension,
      }}
    />
  );
};

Avatar.propTypes = {
  avatar: PropTypes.string,
  avatarAlt: PropTypes.string,
  /**
   * stable number to use for the random background color.
   * not using avatarAlt so that adjacent elements in a table are not the same
   */
  colorId: PropTypes.number,
  dimension: PropTypes.oneOf(["14px", "20px", "32px", "40px", "48px", "96px"]),
  onClick: PropTypes.func,
  trailingMargin: PropTypes.bool,
  useDefault: PropTypes.bool,
};

Avatar.defaultProps = {
  avatar: "",
  avatarAlt: "",
  colorId: 0,
  dimension: "32px",
  onClick: _.noop,
  trailingMargin: false,
  useDefault: false,
};

export default Avatar;
