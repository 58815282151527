/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``yukacon_chevron_left.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ChevronLeftIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M10.7714286,16.0008016 C10.5428542,16.0012523 10.3251382,15.9033238 10.1738286,15.732 L3.77382857,8.532 C3.50438288,8.22911924 3.50438288,7.77248076 3.77382857,7.4696 L10.1738286,0.2696 C10.3636081,0.0560980749 10.6528734,-0.0399396904 10.9326613,0.0176632019 C11.2124491,0.0752660942 11.4402529,0.277758394 11.5302613,0.54886321 C11.6202696,0.819968025 11.5588081,1.11849808 11.3690286,1.332 L5.44102857,8.0008 L11.3690286,14.6696 C11.578839,14.904809 11.6308123,15.241365 11.5017288,15.5289083 C11.3726453,15.8164517 11.0866166,16.0012724 10.7714286,16.0008016"
        transform=""
      />
    </g>
  </svg>
);

ChevronLeftIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ChevronLeftIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

export default ChevronLeftIcon;
