import { useEffect, useCallback } from "react";
import _ from "lodash";
import { useHistory, useSearchParams } from "react-router-dom";
import queryString from "query-string";

import useCurrentTab from "./useCurrentTab";

const useTabRoute = (tabs, paramName = "tab", defaultTab) => {
  const [searchParams, setSearchParams] = useSearchParams?.() || [];
  const history = useHistory?.();

  const currentTab = useCurrentTab(paramName);

  const onSelectV5 = useCallback(
    tabName => {
      if (history && _.includes(tabs, tabName)) {
        const queryParams = queryString.parse(history.location.search);
        const historyModifier = currentTab ? history.push : history.replace;
        // When loading the url for a page that uses query params, the initial page will load
        // with no query params specified. If we simply history.push the query params on top,
        // we can get into an infinite loop with the browser back button where a single click of
        // back will cause the history.push to be called again. So in the case where the page is
        // being loaded for the first time, we want to history.replace instead.
        historyModifier({
          search: queryString.stringify({
            ...queryParams,
            [paramName]: tabName,
          }),
        });
      }
    },
    [currentTab, history, tabs, paramName]
  );

  const onSelect = useCallback(
    tabName => {
      if (_.includes(tabs, tabName) && Boolean(setSearchParams)) {
        const replace = !currentTab;
        // When loading the url for a page that uses query params, the initial page will load
        // with no query params specified. If we simply history.push the query params on top,
        // we can get into an infinite loop with the browser back button where a single click of
        // back will cause the history.push to be called again. So in the case where the page is
        // being loaded for the first time, we want to history.replace instead.
        const newParams = {};
        for (let [existingParam, value] of searchParams.entries()) {
          newParams[existingParam] = value;
        }
        newParams[paramName] = tabName;
        setSearchParams(newParams, { replace });
      }
    },
    [currentTab, setSearchParams, tabs, paramName, searchParams]
  );

  const selectFn = setSearchParams ? onSelect : onSelectV5;
  useEffect(() => {
    if (!currentTab) {
      selectFn(defaultTab || _.head(tabs));
    }
  }, [currentTab, tabs, defaultTab, selectFn]);

  return [currentTab, selectFn];
};

export default useTabRoute;
